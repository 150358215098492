import { useEffect } from 'react'
import { Box3, Vector3 } from 'three'
import { useThree } from '@react-three/fiber'
import { GLTFObject } from '@/lib/cad/GLTFObject'

export const useBounds = (gltf?: GLTFObject) => {
  const { camera } = useThree()

  useEffect(() => {
    if (!gltf) return

    const camInitPos = [1.5, 1, 2]
    const autoZoomFactor = 1
    const bounds = new Box3().setFromObject(gltf?.scene)
    const boundsSizes = bounds.getSize(new Vector3())
    const boundsMaxSize = Math.max(...boundsSizes.toArray())

    const center = bounds.getCenter(new Vector3())
    gltf.scene.position.sub(center)

    if (gltf.wireFrameScene) {
      gltf.wireFrameScene.position.sub(center)
    }

    camera.position.fromArray(camInitPos)
    camera.zoom = autoZoomFactor / boundsMaxSize
    camera.updateProjectionMatrix()
  }, [gltf])
}
