import { useCallback } from 'react'
import { useCadPageParams } from '../../hooks'
import { useDocPageNote } from '@/services/queries/document_notes/useDocPageNote'
import { useEditDocPageNote } from '@/services/queries/document_notes/useEditDocPageNote'
import { useListTemplateAttributes } from '@/services/queries/template_attributes'
import { Editor } from '@/components/editor/Editor'
import { Skeleton } from '@/components/ui/skeleton'
import debounce from 'lodash.debounce'

import type { Editor as TipTapEditor } from '@tiptap/react'
import type { Step } from '@/services/queries/operation_steps/types'

interface StepCardNoteProps {
  step: Step
  isReadOnly?: boolean
}

export const StepCardNote = ({ step, isReadOnly }: StepCardNoteProps) => {
  const { documentId, projectId } = useCadPageParams()
  const documentPageId = step.id as string

  const { isLoading: isLoadingTemplateAttributes } = useListTemplateAttributes({
    documentPageId,
  })

  const notesTemplate = useDocPageNote({
    documentPageId,
  })

  const { mutate: updateNotes } = useEditDocPageNote({
    documentPageId,
  })

  const saveNotes = useCallback(
    debounce(({ editor }: { editor: TipTapEditor }) => {
      updateNotes({
        editor,
        notesPosition:
          notesTemplate?.template_values.notesPosition || 'relative',
      })
    }, 500),
    [updateNotes, notesTemplate?.template_values.notesPosition],
  )

  if (isLoadingTemplateAttributes) {
    return <Skeleton className="h-12 w-8" />
  }

  return (
    <Editor
      projectId={projectId}
      documentId={documentId}
      documentPageId={documentPageId}
      content={notesTemplate?.template_values?.content ?? ''}
      onBlur={saveNotes}
      onUpdate={saveNotes}
      disabled={isReadOnly}
    />
  )
}
