import { useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useCADQuery } from '@/services/queries/cads'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useReorderSteps } from './useReorderSteps'

import { CARD_PLACEHOLDER } from '../constants'

import type { Step } from '@/services/queries/operation_steps/types'
import type { StepCardItem } from '../types'
import { useExplosionsControls } from '../../../CADModel/hooks/useExplosionsControls'

export const useToggleStepCardsList = () => {
  const { data: docData } = useDocumentPageQuery()
  const { data: cadData } = useCADQuery()
  const { mutate: reorderSteps } = useReorderSteps()
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const explosionsToolbar = useCADPageStore((state) => state.explosionsToolbar)
  const colorMap = useCADPageStore(useShallow((state) => state.colorMap))

  const assemblyTree = cadData.rawAssemblyTree
  const gltf = cadData.gltf
  const documentType = docData?.documentType

  const handleExplosions = useExplosionsControls()

  return useCallback(
    ({
      steps,
      previousSteps,
      documentVersionId,
      isReadOnly,
    }: {
      steps: StepCardItem[]
      previousSteps: Step[]
      documentVersionId: string
      isReadOnly: boolean
    }) => {
      const reorderedSteps = steps
        .filter((step) => step !== CARD_PLACEHOLDER)
        .map((step, i) => ({
          ...step,
          order_number: i + 1,
        })) as Step[]

      if (!assemblyTree || !gltf) return

      const cadPageState = getCadPageState()
      const didStepsChange = previousSteps.some(
        (step, i) => step.id !== reorderedSteps[i].id,
      )

      const operationStep = cadPageState.operationStep
      const isStepCardActive = Boolean(operationStep?.isActive)
      const hiddenParts = cadPageState.hiddenParts
      const explosions = cadPageState.explosions
      const isExplosionLinesEnabled = cadPageState.isExplosionLinesEnabled

      const activeStep = reorderedSteps?.find(
        (step) => step.id === operationStep?.stepId,
      )

      const shouldSetVisibility = documentType !== 'project_tracker'
      const shouldToggleOff = isStepCardActive && !didStepsChange

      if (shouldSetVisibility) {
        gltf.setVisibilityForOperationSteps({
          assemblyTree,
          steps: reorderedSteps,
          activeStep: shouldToggleOff ? null : activeStep,
          hiddenParts,
          explosions,
          isExplosionLinesEnabled,
        })
      }

      if (didStepsChange && !isReadOnly) {
        reorderSteps({ documentVersionId, steps: reorderedSteps })
      }

      if (didStepsChange) {
        gltf.unhighlightParts(colorMap)
        setCadPageState({ explosionsToolbar: false, selectedParts: [] })
      } else if (explosionsToolbar) {
        handleExplosions()
      }

      if (shouldToggleOff) {
        setCadPageState({
          operationStep: null,
        })
      }
    },
    [
      assemblyTree,
      documentType,
      getCadPageState,
      gltf,
      reorderSteps,
      setCadPageState,
    ],
  )
}
