import { useEffect, useRef } from 'react'

import { ProjectsTrackerPage } from './ProjectTrackerPage'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { Button } from '@/components/ui/button'
import { Spinner } from '@/components/ui/spinner'
import { CameraPlusIcons } from '@/components/icons/CameraPlusIcon'
import { useListPageViews } from '@/services/queries/views'
import { useListImages } from '@/services/queries/images'
import { useLocation } from 'wouter'
import { usePDFifyStore } from '@/components/pdf/state'
import { Slider } from '@/components/ui/slider'
import { MAIN_DOCUMENT_CONTAINER_TOP_PADDING, useDocumentState } from '@/state'

export const ProjectTracker = () => {
  const { isLoading, data } = useDocumentPageQuery()
  const { isLoading: isLoadingViews, data: viewsData } = useListPageViews({
    cadVersionId: data?.version?.id,
    documentVersionId: data?.documentVersion?.id as string,
  })

  const { data: images, isLoading: isImagesLoading } = useListImages({
    documentVersionId: data?.documentVersion?.id,
  })

  const isGeneratingPdf = usePDFifyStore((state) => state.isGenerating)
  const documentRef = useRef<HTMLDivElement>(null)
  const documentContainerRef = useRef<HTMLDivElement>(null)
  const firstDocRef = useRef<HTMLDivElement>(null)
  const zoom = useDocumentState((state) => state.zoom)
  const defaultZoom = useDocumentState((state) => state.defaultZoom)
  const setZoom = useDocumentState((state) => state.setZoom)
  const setDefaultZoom = useDocumentState((state) => state.setDefaultZoom)

  const [, setLocation] = useLocation()

  useEffect(() => {
    if (documentRef.current) {
      // This is a hack to make sure the DOM repaints the container with the new zoom value
      const n = document.createTextNode('.')
      documentRef.current.appendChild(n)
      documentRef.current.style.visibility = 'hidden'
      setTimeout(() => {
        if (documentRef.current) {
          documentRef.current.removeChild(n)
          documentRef.current.style.visibility = 'visible'
        }
      })

      documentRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'center',
      })
    }
  }, [zoom, documentRef])

  useEffect(() => {
    if (documentContainerRef.current && firstDocRef.current && !defaultZoom) {
      const { height: containerHeight, width: containerWidth } =
        documentContainerRef.current.getBoundingClientRect()

      const { height: firstDocHeight, width: firstDocWidth } =
        firstDocRef.current.getBoundingClientRect()

      const widthRatio = Math.abs(containerWidth / firstDocWidth - 1.05)
      const heightRatio = Math.abs(
        (containerHeight - MAIN_DOCUMENT_CONTAINER_TOP_PADDING) /
          firstDocHeight -
          1.05,
      )

      setDefaultZoom(Math.round(Math.min(widthRatio, heightRatio) * 100))
    }
  }, [
    documentContainerRef,
    firstDocRef,
    isLoading,
    isLoadingViews,
    isImagesLoading,
  ])

  if (isLoading || isLoadingViews || isImagesLoading) {
    return <Spinner />
  }

  const pages = data?.documentPages || []
  const views = viewsData || []
  const projectId = data?.project.id || ''
  const documentId = data?.documentId || ''
  const isLatestDocumentVersion = data?.isLatestDocumentVersion

  return (
    <div className="h-full">
      <div
        className="h-full overflow-scroll relative"
        ref={documentContainerRef}
        style={{
          paddingTop: MAIN_DOCUMENT_CONTAINER_TOP_PADDING,
        }}
      >
        <div
          className="absolute h-full w-full top-0 left-0"
          style={{
            paddingTop: MAIN_DOCUMENT_CONTAINER_TOP_PADDING,
          }}
        >
          <div
            ref={documentRef}
            className="space-y-4 grid justify-items-center px-4 pb-6 w-full"
            style={{
              transform: `scale(${zoom / 100})`,
              transformOrigin: '0 0',
            }}
          >
            {pages.length === 0 && (
              <div className="mt-20 flex flex-col justify-center space-y-2">
                <div className="text-md">
                  Capture a smart view to start tracking your project
                </div>
                <Button
                  className="w-3/6 flex self-center"
                  onClick={() => {
                    setLocation(`/p/${projectId}/document/${documentId}/cad`)
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <CameraPlusIcons className="h-6 w-6 relative left-px stroke-white" />
                    <span>Capture View</span>
                  </div>
                </Button>
              </div>
            )}
            {pages.length > 0 &&
              pages.map((page, i) => {
                const pageViews = views
                  .filter((view) => view.document_page === page.id)
                  .sort((viewA, viewB) => {
                    return (
                      (viewA.document_page_order || 0) -
                      (viewB.document_page_order || 0)
                    )
                  })

                return (
                  <div key={page.id} ref={i === 0 ? firstDocRef : undefined}>
                    <ProjectsTrackerPage
                      documentId={data?.documentId ?? ''}
                      projectId={projectId}
                      pageId={String(page.id)}
                      pageTitle={`Note ${i + 1}`}
                      pageCreatedAt={page.created}
                      documentPage={page}
                      views={pageViews}
                      images={images || []}
                      isReadOnly={isGeneratingPdf || !isLatestDocumentVersion}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      <div className="absolute w-40 p-2 bottom-4 right-4 flex items-center flex-col bg-slate-50/50 rounded-md">
        <label id="zoom-label" className="text-sm mb-1">
          {Math.round(zoom - defaultZoom)}%
        </label>
        <Slider
          aria-describedby="zoom-label"
          min={50}
          max={200}
          step={5}
          value={[zoom - defaultZoom]}
          defaultValue={[100]}
          onValueChange={([val]) => setZoom(val + defaultZoom)}
        />
      </div>
    </div>
  )
}
