import { useEffect, useRef } from 'react'

import {
  MAIN_DOCUMENT_CONTAINER_TOP_PADDING,
  useAssemblyTree,
  useDocumentState,
} from '@/state'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { usePDFifyStore } from '@/components/pdf/state'

import { OperationDocument } from '../../OperationDocument'
import { TitleDocument } from '../../TitleDocument'
import { BillOfMaterialsDocument } from '../../BillOfMaterialsDocument'
import { VersionHistoryDocument } from '../../VersionHistory'
import { Slider } from '@/components/ui/slider'
import { useListPageViews } from '@/services/queries/views'

export const WorkInstructions = () => {
  const { isLoading, data } = useDocumentPageQuery()
  const { isLoading: isLoadingViews, data: viewsData } = useListPageViews({
    cadVersionId: data?.version?.id,
    documentVersionId: data?.documentVersion?.id as string,
  })
  const assemblyTree = useAssemblyTree((state) => state.tree)
  const isGeneratingPdf = usePDFifyStore((state) => state.isGenerating)
  const documentRef = useRef<HTMLDivElement>(null)
  const documentContainerRef = useRef<HTMLDivElement>(null)
  const firstDocRef = useRef<HTMLDivElement>(null)
  const zoom = useDocumentState((state) => state.zoom)
  const defaultZoom = useDocumentState((state) => state.defaultZoom)
  const setZoom = useDocumentState((state) => state.setZoom)
  const setDefaultZoom = useDocumentState((state) => state.setDefaultZoom)

  useEffect(() => {
    if (documentRef.current) {
      // This is a hack to make sure the DOM repaints the container with the new zoom value
      const n = document.createTextNode('.')
      documentRef.current.appendChild(n)
      documentRef.current.style.visibility = 'hidden'
      setTimeout(() => {
        if (documentRef.current) {
          documentRef.current.removeChild(n)
          documentRef.current.style.visibility = 'visible'
        }
      })

      documentRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'center',
      })
    }
  }, [zoom, documentRef])

  useEffect(() => {
    if (documentContainerRef.current && firstDocRef.current && !defaultZoom) {
      const { height: containerHeight, width: containerWidth } =
        documentContainerRef.current.getBoundingClientRect()

      const { height: firstDocHeight, width: firstDocWidth } =
        firstDocRef.current.getBoundingClientRect()

      const widthRatio = Math.abs(containerWidth / firstDocWidth - 1.05)
      const heightRatio = Math.abs(
        (containerHeight - MAIN_DOCUMENT_CONTAINER_TOP_PADDING) /
          firstDocHeight -
          1.05,
      )

      setDefaultZoom(Math.round(Math.min(widthRatio, heightRatio) * 100))
    }
  }, [
    documentContainerRef,
    firstDocRef,
    assemblyTree,
    data?.project,
    data?.version,
    data?.documentPages,
  ])

  if (isLoading || isLoadingViews) {
    return <div>Loading...</div>
  }

  if (
    !assemblyTree ||
    !data ||
    !data.project ||
    !data.version ||
    !data.documentPages
  ) {
    return (
      <div className="h-full overflow-scroll relative pt-14">
        <div className="flex flex-col items-center mt-20">
          <h1 className="text-lg font-semibold">No document found.</h1>
          <h2 className="text-gray-500">
            Capture a smart screenshot to get started.
          </h2>
        </div>
      </div>
    )
  }

  const versionNumber = data.documentVersion?.version_number || 1
  const versionName = data.documentVersion?.name || `Version ${versionNumber}`
  const lastUpdated =
    data.documentVersion?.modified ||
    data.documentVersion?.created ||
    new Date().toISOString()
  const isLatestDocumentVersion = data.isLatestDocumentVersion
  const documentViews = viewsData || []
  const documentName = data.doc?.name || 'Work Instructions'

  return (
    <div className="h-full">
      <div
        className="h-full overflow-scroll relative"
        ref={documentContainerRef}
        style={{
          paddingTop: MAIN_DOCUMENT_CONTAINER_TOP_PADDING,
        }}
      >
        <div
          className="absolute h-full w-full top-0 left-0"
          style={{
            paddingTop: MAIN_DOCUMENT_CONTAINER_TOP_PADDING,
          }}
        >
          <div
            ref={documentRef}
            className="space-y-4 grid justify-items-center px-4 pb-6 w-full"
            style={{
              transform: `scale(${zoom / 100})`,
              transformOrigin: '0 0',
            }}
          >
            <TitleDocument
              parentRef={firstDocRef}
              documentName={documentName}
              versionName={versionName}
              lastUpdated={lastUpdated}
              isLatestDocumentVersion={isLatestDocumentVersion}
            />
            <VersionHistoryDocument
              versionName={versionName}
              lastUpdated={lastUpdated}
              isLatestDocumentVersion={isLatestDocumentVersion}
            />
            <BillOfMaterialsDocument
              versionName={versionName}
              lastUpdated={lastUpdated}
              isLatestDocumentVersion={isLatestDocumentVersion}
            />
            {data.documentPages.map((page, i) => {
              const views = documentViews
                .filter((view) => view.document_page === page.id)
                .sort((viewA, viewB) => {
                  return (
                    (viewA.document_page_order || 0) -
                    (viewB.document_page_order || 0)
                  )
                })
              return (
                <OperationDocument
                  key={page.id}
                  documentPage={page}
                  views={views}
                  versionName={versionName}
                  lastUpdated={lastUpdated}
                  orderNumber={i + 1}
                  isReadOnly={isGeneratingPdf || !isLatestDocumentVersion}
                  isLatestDocumentVersion={isLatestDocumentVersion}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div className="absolute w-40 p-2 bottom-4 right-4 flex items-center flex-col bg-slate-50/50 rounded-md">
        <label id="zoom-label" className="text-sm mb-1">
          {Math.round(zoom - defaultZoom)}%
        </label>
        <Slider
          aria-describedby="zoom-label"
          min={50}
          max={200}
          step={5}
          value={[zoom - defaultZoom]}
          defaultValue={[100]}
          onValueChange={([val]) => setZoom(val + defaultZoom)}
        />
      </div>
    </div>
  )
}
