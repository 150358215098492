import { useShallow } from 'zustand/react/shallow'

import { Button } from '@/components/ui/button'
import { CameraPlusIcons } from '@/components/icons/CameraPlusIcon'
import { Spinner } from '@/components/ui/spinner'

import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useCreateCadView } from './hooks/useCreateCadView'
import { useIsReadOnly } from '@/pages/CADPage/hooks/useIsReadOnly'
import { useCallback } from 'react'

export const CreateViewButton = () => {
  const { isLoading: isLoadingCadData, data: cadData } = useCADQuery()
  const { isLoading: isLoadingDocPage, data: docData } = useDocumentPageQuery()
  const { isLoading: isReadOnlyInfoLoading, data: readOnlyInfo } =
    useIsReadOnly()

  const operationStep = useCADPageStore((state) => state.operationStep)

  const cadVersionId = cadData?.version?.id
  const documentVersionId = docData?.documentVersion?.id
  const documentType = docData?.documentType

  const { mutateAsync: createView, isPending: isCreateViewPending } =
    useCreateCadView({
      cadVersionId,
      documentVersionId,
      documentType,
      documentPageId: operationStep?.stepId,
    })

  const setHover = useCADPageStore(
    useShallow((state) => state.setCreateViewButtonHover),
  )

  const isLoading =
    isLoadingCadData || isLoadingDocPage || isReadOnlyInfoLoading

  const handleCreateView = useCallback(async () => {
    const documentPageId = operationStep?.stepId

    if (!documentPageId) {
      return
    }

    await createView({
      documentPageId,
    })
  }, [operationStep, createView])

  if (isLoading || !cadData) {
    return null
  }

  const isLatestCadVersion = cadData.isLatestCadVersion

  const isCreateViewButtonDisabled =
    isCreateViewPending ||
    !isLatestCadVersion ||
    !operationStep ||
    Boolean(readOnlyInfo?.isReadOnly)

  return (
    <Button
      className="w-20 h-12 rounded-full shadow-md disabled:bg-gray-400 disabled:cursor-not-allowed"
      disabled={isCreateViewButtonDisabled}
      onClick={handleCreateView}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex items-center space-x-2">
        {isCreateViewPending ? (
          <Spinner />
        ) : (
          <CameraPlusIcons className="h-8 w-8 relative left-px stroke-white" />
        )}
      </div>
    </Button>
  )
}
