import React from 'react'
import { create } from 'zustand'
import { Group, Object3D } from 'three'
import { v4 as uuidv4 } from 'uuid'
import { persist } from 'zustand/middleware'

const LOCAL_STORAGE_KEY = 'q20-cad-page-store'

export type Explosions = {
  [instanceName: string]: {
    position: [number, number, number]
    originalPosition: [number, number, number]
  }
}

export type SaveableExposions = {
  id: string
  cadId: string
  explosions: Explosions
}

export type WandButtonState = string | null

export type RenderMode = 'full-color' | 'outline'

export type StateVariables = {
  cameraAspect: number
  cameraPosition: [number, number, number]
  cameraFov: number
  cameraZoom: number
  cameraQuaternion: number[]
  cameraUp: number[]
  selectedParts: string[]
  hiddenParts: string[]
  renderMode: RenderMode
  colorMap: {
    [key: string]: number
  }
  transparentParts: string[]
  highlightedPartUUID: string | null
  showAxis: boolean
  isRotating: boolean
  isDragging: boolean
  isExplosionLinesEnabled: boolean
  wandSelected: WandButtonState
  explodeObjectRef: React.RefObject<Object3D | Group> | null
  explosions: Explosions
  explosionsToolbar: boolean
  loadedExplosion: string
  savedExplosions: SaveableExposions[]
  loadingProgress: number
  createViewButtonHover: boolean
  operationStep?: {
    documentId: string
    stepId: string
    selectFromCad: boolean
    isActive: boolean
  } | null
  filterScreenshotsByDocumentPageId?: string | null
}

export type StateActions = {
  updatePartColor: (
    partName: string,
    color: number | string,
    isColored: boolean,
  ) => void
  isColored: (partName: string) => boolean
  updateTransparency: (partName: string) => void
  isTransparent: (partName: string) => boolean
  isHidden: (uuid: string) => boolean
  setState: (state: Partial<CADPageState>) => void
  getState: () => CADPageState
  pushSelectedPart: (uuid: string) => void
  setExplosion: (
    instanceName: string,
    oldPositions: [number, number, number],
    newPosition: [number, number, number],
  ) => void
  toggleAxis: () => void

  setWandSelected: (wandState: WandButtonState) => void
  setExplodeObjectRef: (ref: React.RefObject<Object3D | Group> | null) => void
  saveNewExplosion: (cadId: string) => void
  toggleExplosions: () => void
  getHighlightedPartUUID: () => string | null
  setLoadingProgress: (progress: number) => void
  setCreateViewButtonHover: (hover: boolean) => void
  reset: () => void
}

export type CADPageState = StateVariables & StateActions

const initialState: StateVariables = {
  cameraAspect: 1,
  cameraPosition: [Math.PI, Math.PI / 4, Math.PI / 2],
  cameraFov: 45,
  cameraZoom: 1,
  cameraQuaternion: [],
  cameraUp: [],
  selectedParts: [],
  hiddenParts: [],
  transparentParts: [],
  renderMode: 'full-color',
  colorMap: {},
  highlightedPartUUID: null,
  showAxis: false,
  isRotating: false,
  isDragging: false,
  isExplosionLinesEnabled: false,
  wandSelected: null,
  explodeObjectRef: null,
  explosions: {},
  savedExplosions: [],
  explosionsToolbar: false,
  loadedExplosion: '',
  loadingProgress: 0,
  createViewButtonHover: false,
  operationStep: null,
  filterScreenshotsByDocumentPageId: null,
}

export const useCADPageStore = create<CADPageState>()(
  persist(
    (set, get) => ({
      ...initialState,

      reset: () => {
        set(initialState)
        localStorage.removeItem(LOCAL_STORAGE_KEY)
      },

      setState: (state: Partial<CADPageState>) => {
        set(state)
      },
      getState: () => get(),
      isHidden: (partName: string) => {
        return get().hiddenParts.includes(partName)
      },
      pushSelectedPart: (uuid: string) => {
        set((state) => ({
          selectedParts: [...new Set([...state.selectedParts, uuid])],
        }))
      },
      toggleAxis: () => set((state) => ({ showAxis: !state.showAxis })),
      setExplodeObjectRef: (ref: React.RefObject<Object3D | Group> | null) => {
        set({ explodeObjectRef: ref })
      },
      setExplosion: (
        instanceName: string,
        oldPositions: [number, number, number],
        newPosition: [number, number, number],
      ) =>
        set((state) => {
          const explosions = { ...state.explosions }
          if (explosions[instanceName]) {
            explosions[instanceName].position = newPosition
          } else {
            explosions[instanceName] = {
              position: newPosition,
              originalPosition: oldPositions,
            }
          }
          return { explosions }
        }),
      saveNewExplosion: (cadId: string) => {
        set((state) => {
          const newExplosions = JSON.parse(JSON.stringify(state.explosions))
          const newSaveableExplosions: SaveableExposions = {
            cadId,
            id: uuidv4(),
            explosions: newExplosions,
          }
          return {
            savedExplosions: [...state.savedExplosions, newSaveableExplosions],
            loadedExplosion: newSaveableExplosions.id,
          }
        })
      },
      toggleExplosions: () =>
        set((state) => ({ explosionsToolbar: !state.explosionsToolbar })),
      setWandSelected: (wandState: WandButtonState) => {
        const currentState = get().wandSelected
        if (currentState === wandState) set({ wandSelected: null })
        else set({ wandSelected: wandState })
      },
      updatePartColor: (
        partName: string,
        color: number | string,
        addColor: boolean,
      ) => {
        if (addColor) {
          if (get().isTransparent(partName)) {
            set((state) => ({
              transparentParts: state.transparentParts.filter(
                (part) => part !== partName,
              ),
            }))
          }
          const parsedColor =
            typeof color === 'number' ? color : parseInt(color.slice(1), 16)
          set((state) => ({
            colorMap: {
              ...state.colorMap,
              [partName]: parsedColor,
            },
          }))
        } else {
          set((state) => {
            const newColorMap = Object.fromEntries(
              Object.entries(state.colorMap).filter(
                ([key]) => key !== partName,
              ),
            )
            return { colorMap: newColorMap }
          })
        }
      },
      isColored: (partName: string) => {
        return get().colorMap[partName] !== undefined
      },
      updateTransparency: (partName: string) => {
        set((state) => {
          if (!get().isTransparent(partName)) {
            if (get().isColored(partName)) {
              set((state) => {
                const newColorMap = Object.fromEntries(
                  Object.entries(state.colorMap).filter(
                    ([key]) => key !== partName,
                  ),
                )
                return { colorMap: newColorMap }
              })
            }
            return {
              transparentParts: [
                ...new Set([...state.transparentParts, partName]),
              ],
            }
          } else {
            return {
              transparentParts: state.transparentParts.filter(
                (part) => part !== partName,
              ),
            }
          }
        })
      },
      isTransparent: (partName: string) => {
        return get().transparentParts.includes(partName)
      },
      getHighlightedPartUUID: () => {
        return get().highlightedPartUUID
      },
      setLoadingProgress: (progress: number) =>
        set({ loadingProgress: progress }),
      setCreateViewButtonHover: (hover: boolean) => {
        set({ createViewButtonHover: hover })
      },
    }),
    {
      name: LOCAL_STORAGE_KEY,
      partialize: (state) => ({ savedExplosions: state.savedExplosions }),
    },
  ),
)
