import { MinimalLayout } from '@/components/layouts/MinimalLayout'
import { CreateDocumentButton } from '@/pages/ProjectsPage/components/CreateDocumentButton'
import { Navbar } from '@/components/nav/Navbar'

import { ProjectsTable } from '@/pages/ProjectsPage/components/ProjectsTable'

export const ProjectsPage = () => {
  return (
    <MinimalLayout>
      <Navbar>
        <div className="flex items-center space-x-4 h-10 py-8 w-full"></div>
        <CreateDocumentButton />
      </Navbar>
      <div className="relative mt-4 py-4 px-2 w-full rounded-xl bg-white">
        <div className="flex items-center justify-between">
          <h1 className="text-normal font-semibold tracking-[-0.015em] text-zinc-950 mx-10">
            Projects
          </h1>
        </div>
        <ProjectsTable />
      </div>
    </MinimalLayout>
  )
}
