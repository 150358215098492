import { Suspense, useMemo } from 'react'
import { MathUtils, PerspectiveCamera } from 'three'
import { Canvas } from '@react-three/fiber'
import {
  GizmoHelper,
  GizmoViewcube,
  TrackballControls,
  Environment,
  SpotLight,
} from '@react-three/drei'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import { Model } from './CADModel'
import { CADLoading } from './CADLoading'
import { useCADPageStore } from '../state'
import { useShallow } from 'zustand/react/shallow'
import { ScreenshotOverlay } from './ScreenshotOverlay'
import { ScreenshotPanelButton } from './ScreenshotPanelButton'

import hdrImage from '@/assets/potsdamer_platz_1k_micro.hdr?url'
import { useCADQuery } from '@/services/queries/cads'
import { ReuploadCadButton } from '@/pages/DocumentPage/components/ReuploadCadButton'
import { COLOR_OPTIONS } from '@/constants'
import { eventManagerFactory } from './CADModel/hooks/useCanvasEventManager'

export const CADEditor = () => {
  const { isLoading, data } = useCADQuery()
  const showAxis = useCADPageStore((state) => state.showAxis)
  const isDragging = useCADPageStore((state) => state.isDragging)
  const explosionsToolbar = useCADPageStore((state) => state.explosionsToolbar)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const wandSelected = useCADPageStore((state) => state.wandSelected)

  const cadVersionId = data.version?.id
  const currentColorName = useMemo(() => {
    if (wandSelected === 'transparency') {
      return 'transparency'
    }

    if (wandSelected) {
      const selectedColor = COLOR_OPTIONS.find(
        (color) => color.value === parseInt(wandSelected.slice(1), 16),
      )
      return selectedColor?.name
    }
  }, [wandSelected])

  if (data.hasNoDownloadUrl && !isLoading) {
    return (
      <div className="flex flex-col mt-16 items-center">
        <ExclamationTriangleIcon className="h-8 w-8 text-red-500" />
        <h1 className="text-lg">Uploading CAD file was unsuccessful.</h1>
        <h2 className="text-lg">Please attempt to upload again.</h2>
        <div className="mt-6">
          {cadVersionId && <ReuploadCadButton cadVersionId={cadVersionId} />}
        </div>
      </div>
    )
  }

  return (
    <Canvas
      id="cad-canvas"
      events={eventManagerFactory}
      className={currentColorName ? `cursor-wand__${currentColorName}` : ''}
      fallback={<div>Loading...</div>}
      gl={{
        toneMappingExposure: 0.8,
        preserveDrawingBuffer: true,
      }}
      performance={{ min: 0.5 }}
      dpr={[1, 1.5]}
      camera={{
        position: [Math.PI, Math.PI / 4, Math.PI / 2],
        fov: 45,
        zoom: 1,
      }}
      onPointerDown={() => {
        if (explosionsToolbar)
          setTimeout(() => setCadPageState({ isRotating: true }))
        else setCadPageState({ isRotating: true })
      }}
      onPointerUp={() => {
        if (explosionsToolbar)
          setTimeout(() => setCadPageState({ isRotating: false }))
        else setCadPageState({ isRotating: false })
      }}
    >
      <Suspense fallback={<CADLoading />}>
        <ScreenshotPanelButton />
        <ambientLight intensity={0.8} />
        <Model />
        <axesHelper args={[0.2]} visible={showAxis} />
      </Suspense>

      <Environment files={hdrImage} />

      <SpotLight
        distance={50}
        intensity={100}
        angle={MathUtils.degToRad(45)}
        color={0xffffff}
        position={[0, -Math.PI, 0]}
        volumetric={false}
      />

      {!isDragging && (
        <TrackballControls
          makeDefault
          onChange={({ target }: any) => {
            const camera = target?.object as unknown as PerspectiveCamera
            setCadPageState({
              cameraAspect: camera.aspect,
              cameraPosition: camera.position.toArray(),
              cameraFov: camera.fov,
              cameraZoom: camera.zoom,
              cameraQuaternion: camera.quaternion.toArray(),
              cameraUp: camera.up.toArray(),
            })
          }}
          zoomSpeed={2.5}
          dynamicDampingFactor={0}
          staticMoving={true}
          panSpeed={0.05}
          rotateSpeed={2.5}
          enabled={!isDragging}
        />
      )}

      <GizmoHelper alignment="bottom-left" margin={[80, 180]}>
        <GizmoViewcube color="#e1e1e6" textColor="#000000" />
      </GizmoHelper>
      <ScreenshotOverlay />
    </Canvas>
  )
}
