import { ImageData } from '@/lib/api/client'
import { useCADPageStore } from '@/pages/CADPage/state'

export const useImageData = (): ImageData => {
  const explosions = useCADPageStore((state) => state.explosions)
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const colorMap = useCADPageStore((state) => state.colorMap)
  const transparentParts = useCADPageStore((state) => state.transparentParts)
  const cameraAspect = useCADPageStore((state) => state.cameraAspect)
  const cameraPosition = useCADPageStore((state) => state.cameraPosition)
  const cameraFov = useCADPageStore((state) => state.cameraFov)
  const cameraZoom = useCADPageStore((state) => state.cameraZoom)
  const cameraQuaternion = useCADPageStore((state) => state.cameraQuaternion)
  const cameraUp = useCADPageStore((state) => state.cameraUp)
  const isExplosionLinesEnabled = useCADPageStore(
    (state) => state.isExplosionLinesEnabled,
  )
  const renderMode = useCADPageStore((state) => state.renderMode)

  const imageData: ImageData = {
    cameraAspect,
    cameraPosition,
    cameraFov,
    cameraZoom,
    cameraQuaternion,
    cameraUp,
    explosions,
    hiddenParts,
    colorMap,
    transparentParts,
    isExplosionLinesEnabled,
    renderMode,
  }

  return imageData
}
