import { useMutation, useQuery } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { createImage, listImages, deleteImage } from '@/lib/api/client'
import { uploadFile } from '@/lib/api/client'
import { DocumentPage } from '@/lib/api/client'
import { truncate } from '@/lib/truncate'
import queryClient from '@/queryClient'

import {
  getImageTemplatesQuery,
  getNotesQuery,
  useCreateImageTemplate,
  useDeleteImageTemplate,
  useDeleteNotes,
} from '@/services/hooks/template_attributes'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'

/*
 ** Views in cache need to be updated after 2 minutes
 ** of inactivity to update the download URL
 */
const IMAGES_CACHE_TIME = 120000

export const useCreateImage = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { mutateAsync: createImageTemplate } = useCreateImageTemplate()

  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_IMAGE],
    mutationFn: async ({
      documentPage,
      file,
    }: {
      documentPage: DocumentPage
      file: File
    }) => {
      const documentPageId = documentPage.id
      if (!documentPageId) {
        throw new Error('No document ID found')
      }

      const documentVersionId = documentPage.document_version
      const image = await createImage(documentVersionId, {
        source_type: 'screenshot',
        name: truncate(file.name),
      })

      if (!image.upload_url || !image.id) {
        throw new Error('Unable to create image')
      }

      await createImageTemplate({
        documentPageId,
        imageData: {
          imageId: image.id,
        },
      })

      await uploadFile(image.upload_url, file)

      return {
        documentPage,
        image,
      }
    },
    onSuccess: ({ documentPage, image }) => {
      queryClient.setQueriesData(
        {
          queryKey: [
            QUERY_KEYS.IMAGES,
            { documentVersionId: documentPage.document_version },
          ],
        },
        (images: Array<any> | undefined) => {
          if (images) {
            return [...images, image]
          }

          return [image]
        },
      )

      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    },
  })
}

export const useListImages = ({
  documentVersionId,
}: {
  documentVersionId?: string | null
}) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.IMAGES, { documentVersionId }],
    staleTime: IMAGES_CACHE_TIME,
    queryFn: () => {
      try {
        return documentVersionId ? listImages(documentVersionId) : []
      } catch (error) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem with fetching images.',
        })
        return []
      }
    },
    enabled: !!documentVersionId,
  })
}

export const useDeleteImages = (props?: { onDelete?: () => void }) => {
  const { onDelete } = props || {}
  const { mutateAsync: deleteImageTemplate } = useDeleteImageTemplate()
  const { mutateAsync: deleteNotes } = useDeleteNotes()

  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_IMAGE],
    mutationFn: async ({
      imageId,
      documentPage,
    }: {
      imageId: string
      documentPage: DocumentPage
    }) => {
      const documentPageId = documentPage.id
      if (!documentPageId) {
        return
      }

      await deleteImage(imageId)

      const imageTemplates = await getImageTemplatesQuery(documentPageId)
      const image = imageTemplates.find(
        (template) => template.template_values.imageId === imageId,
      )

      if (!image) return

      await deleteImageTemplate(image.id as string)

      const notes = await getNotesQuery(documentPageId)
      const imageNotes = notes.filter(
        (note) => note.template_values?.viewId === imageId,
      )

      await Promise.all(
        imageNotes.map((note) => deleteNotes(note.id as string)),
      )

      return { documentVersionId: documentPage.document_version }
    },
    onSuccess: (documentVersionId) => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.IMAGES,
          documentVersionId ? { documentVersionId } : {},
        ],
      })
      if (typeof onDelete === 'function') {
        onDelete()
      }
    },
  })
}
