import { AddToolForm } from '@/components/editor/components/AddToolForm'
import { createMark } from '@/components/editor/lib/createMark'
import {
  createTableMutation,
  toolsTableQuery,
  updateTableMutation,
} from '@/services/hooks/template_attributes'
import type { EditorAttributes } from '@/components/editor/lib/types'
import type { CommandOption } from './types'

const MAX_ADDITIONAL_ROWS = 3

const command: CommandOption = {
  name: 'tools',
  path: '/tools:',
  multiSelect: false,
  getItems: async ({ documentPageId, toolsBom }) =>
    toolsBom.map((tool) => ({
      key: `${documentPageId}-${tool.toolName}`,
      value: tool.toolName,
    })),
  onSelect: async ({
    editor,
    range,
    items,
    query,
    fullToolsBom,
    templateAttributes,
    updateBomOnSelect = false,
    toast,
  }) => {
    const editorAttributes = editor.options.editorProps
      .attributes as EditorAttributes
    const projectId = editorAttributes.projectId
    const documentPageId = editorAttributes.documentPageId
    const documentId = editorAttributes.documentId

    const tools: string[] = []

    const customTools =
      templateAttributes.find(
        (template) =>
          template.data_type === 'table' &&
          template.template_values.type === 'tools-table',
      )?.template_values?.rows || []

    customTools.forEach((tool: any) => {
      if (!tool.toolName) return
      tools.push(tool.toolName)
    })

    const toolQuery = query.replace('tools:', '')

    const hasTool = tools.includes(toolQuery)

    const filteredItems = items.filter((item) =>
      item.value.toLowerCase().includes(toolQuery.toLowerCase()),
    )
    const isInBom =
      !!fullToolsBom.find((tool) => tool.toolName === items[0]?.value) &&
      !tools.find((tool) => tool === items[0]?.value)

    const createCustomRow = async ({
      documentPageId,
      customRow,
    }: {
      documentPageId: string
      customRow: {
        toolName: string
        quantity: string
      }
    }) => {
      const tableTemplate = await toolsTableQuery(documentPageId)
      const TABLE_TYPE = 'tools-table'

      if (tableTemplate) {
        await updateTableMutation().execute({
          templateAttributeId: tableTemplate.id as string,
          documentPageId,
          tableData: {
            type: TABLE_TYPE,
            rows: [...(tableTemplate.template_values.rows || []), customRow],
          },
        })
      } else {
        await createTableMutation().execute({
          documentPageId,
          tableData: {
            type: TABLE_TYPE,
            rows: [customRow],
          },
        })
      }
    }

    const shouldAddNewTool =
      customTools.length < MAX_ADDITIONAL_ROWS &&
      !hasTool &&
      (filteredItems.length === 0 || isInBom) &&
      (toolQuery.trim() !== '' || isInBom)

    const addFromBom = filteredItems.length && isInBom
    const toolName = addFromBom ? items[0]?.value : toolQuery
    const MARK_COLOR = '#E5D5FF'

    if (shouldAddNewTool && updateBomOnSelect) {
      try {
        await createCustomRow({
          documentPageId: documentPageId as string,
          customRow: {
            toolName,
            quantity: '',
          },
        })
        toast({
          variant: 'default',
          description: (
            <div>
              <div className="px-2 py-2 break-all">
                Added{' '}
                <span
                  style={{
                    padding: '2px 6px',
                    borderRadius: '5px',
                    backgroundColor: MARK_COLOR,
                    color: 'rgb(30, 30, 30)',
                  }}
                >
                  {toolName}
                </span>{' '}
                to {`${addFromBom ? 'Operation' : 'Tools BOM'}`}
              </div>
            </div>
          ),
        })
      } catch (error) {
        console.error(error)
        toast({
          variant: 'destructive',
          title: `Failed to add tool ${toolName} to BOM`,
        })
      }
    }

    const selectedTools = new Set<string>()

    if (
      shouldAddNewTool ||
      (!updateBomOnSelect && !filteredItems.length && toolName)
    ) {
      selectedTools.add(toolName)
    } else {
      filteredItems.forEach((item) => {
        selectedTools.add(item.value)
      })
    }

    const content = Array.from(selectedTools)
      .map((item) =>
        createMark({
          projectId: projectId as string,
          documentId: documentId as string,
          text: item,
          hash: `tool-${documentPageId}-${item.replace(/\s/g, '-')}`,
          color: MARK_COLOR,
        }),
      )
      .join(' ')

    editor.chain().focus().deleteRange(range).insertContent(content).run()
  },
  renderEmptyResults: ({ query, editor, range, updateBomOnSelect }) => (
    <AddToolForm
      query={query}
      editor={editor}
      range={range}
      updateBomOnSelect={updateBomOnSelect}
    />
  ),
}

export default command
