import { useQuery, useMutation } from '@tanstack/react-query'

import {
  createDocument,
  listDocuments,
  getDocument,
  Document,
  DocumentTypeChoices,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'

type mutationDocumentProps = {
  onSuccess?: (response: { document: Document; projectId: string }) => void
}

export const useCreateDocument = (props?: mutationDocumentProps) => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_DOCUMENT],
    mutationFn: async ({
      projectId,
      values,
    }: {
      projectId: string
      values: {
        name: string
        cad_id: string
        document_type: DocumentTypeChoices
      }
    }) => {
      const document = await createDocument(projectId, values)

      return {
        document,
        projectId,
      }
    },
    onSuccess: ({ document, projectId }) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DOCUMENTS, { projectId }],
      })
      queryClient.setQueryData(
        [QUERY_KEYS.DOCUMENT, { documentId: document?.id }],
        document,
      )
      if (props?.onSuccess)
        props.onSuccess({
          document,
          projectId,
        })
    },
  })
}

export const useListDocuments = ({ projectId }: { projectId: string }) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENTS, { projectId }],
    queryFn: () => listDocuments(projectId),
  })
}

export const listDocumentsQuery = ({ projectId }: { projectId: string }) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.DOCUMENTS, { projectId }],
    queryFn: () => listDocuments(projectId),
  })
}

export const useDocument = ({ documentId }: { documentId: string }) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT, { documentId }],
    queryFn: () => getDocument(documentId),
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
