import { Html } from '@react-three/drei'
import { ThreeEvent } from '@react-three/fiber'

import { CADPartLabel } from '../CADPartLabel'
import { Progress } from '@/components/ui/progress'
import { ExplosionsControls } from './ExplosionsControls'
import { ControlPanel } from '@/pages/CADPage/components/ControlPanel'

import { useCADPageStore } from '../../state'
import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

import { useBounds } from './hooks/useBounds'
import { useWireframe } from './hooks/useWireframe'
import { useModelHandlers } from './hooks/useModelHandlers'
import { useExplosionLines } from './hooks/useExplosionLines'
import { useMergeMeshes } from './hooks/useMergeMeshes'

export const Model = () => {
  const { isLoading: isLoadingCAD, data } = useCADQuery()
  const { isLoading: isLoadingDoc, data: docData } = useDocumentPageQuery()
  const gltf = data?.gltf
  const documentType = docData?.documentType

  const loading = Math.round(useCADPageStore((state) => state.loadingProgress))
  const explosionsToolbar = useCADPageStore((state) => state.explosionsToolbar)
  const renderMode = useCADPageStore((state) => state.renderMode)
  const handlers = useModelHandlers()

  useBounds(gltf)
  useExplosionLines(gltf)
  useWireframe(gltf)
  useMergeMeshes(gltf)

  const isLoading = isLoadingCAD || isLoadingDoc
  if (isLoading || !documentType || !gltf) {
    return (
      <Html center zIndexRange={[50, 0]} className="w-80 flex flex-col gap-2.5">
        <Progress value={loading} className="w-full" />
        <span className="text-xs text-gray-500 font-semibold text-center">
          {loading}%
        </span>
      </Html>
    )
  }

  /**
   * General purpose handler for pointer events on the CAD Model.
   *
   * This handler calls setTimeout while the explosions toolbar is open to ensure
   * that any transform controls events are processed first.
   *
   * @param event - The pointer event.
   * @param handler - The respective event handler function.
   */
  const handlePointerEvent = (
    event: ThreeEvent<PointerEvent>,
    handler: (event: ThreeEvent<PointerEvent>) => void,
  ) => {
    event.stopPropagation()
    if (explosionsToolbar) {
      setTimeout(() => handler(event))
    } else {
      handler(event)
    }
  }

  return (
    <>
      <primitive
        object={gltf?.scene ?? {}}
        onPointerEnter={(event: ThreeEvent<PointerEvent>) => {
          handlePointerEvent(event, handlers.handlePointerEnter)
        }}
        onPointerLeave={(event: ThreeEvent<PointerEvent>) => {
          handlePointerEvent(event, handlers.handlePointerUpAndLeave)
        }}
        onPointerDown={(event: ThreeEvent<PointerEvent>) => {
          handlePointerEvent(event, handlers.handlePointerDown)
        }}
        onPointerUp={(event: ThreeEvent<PointerEvent>) => {
          const target = event.target as Element
          target.releasePointerCapture(event.pointerId)
          handlePointerEvent(event, handlers.handlePointerUpAndLeave)
        }}
      />
      {gltf.wireFrameScene && renderMode === 'outline' && (
        <primitive object={gltf?.wireFrameScene ?? {}} />
      )}
      <>{gltf.scene && <ControlPanel />}</>
      <CADPartLabel documentType={documentType} />
      <ExplosionsControls />
    </>
  )
}
