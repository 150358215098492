import { useSearch, useLocation } from 'wouter'
import queryString from 'query-string'
import { useShallow } from 'zustand/react/shallow'
import { Skeleton } from '@/components/ui/skeleton'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { ProjectDocumentTitle } from '@/components/core/ProjectDocumentTitle'
import type { DocumentPage } from '@/lib/api/client'
import { useDocumentState } from '@/state'
import { useCADQuery } from '@/services/queries/cads'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { cn } from '@/utils'

export const ProjectTrackerTOC = () => {
  const { isLoading, data } = useDocumentPageQuery()
  const { isLoading: isCADLoading, data: cadData } = useCADQuery()

  if (isLoading || isCADLoading) {
    return <Skeleton className="w-full h-6 bg-gray-200" />
  }

  const assemblyTree = cadData.rawAssemblyTree
  const project = data?.project

  if (!project || !assemblyTree) {
    return null
  }

  return (
    <div className="h-full overflow-auto pb-5 text-sm">
      <div className="my-4 px-10">
        <ProjectDocumentTitle />
      </div>
      <div className="flex flex-col">
        {data.documentPages.map((docPage) => {
          return <TrackerItem key={docPage.id} docPage={docPage} />
        })}
      </div>
    </div>
  )
}

const TrackerItem = ({ docPage }: { docPage: DocumentPage }) => {
  const currentPageInView = useDocumentState(
    useShallow((state) => state.currentPageInView),
  )
  const [, setLocation] = useLocation()
  const search = useSearch()
  const { projectId, documentId } = useCadPageParams()

  return (
    <a
      href={`#`}
      className={cn(
        'flex items-center space-x-2 p-2 pl-10 font-medium hover:bg-indigo-50 hover:text-primary-50',
        {
          'bg-indigo-100': currentPageInView === `${docPage.id}`,
        },
      )}
      onClick={(e) => {
        e.preventDefault()
        const searchParams = queryString.parse(search) as {
          cv?: string // cad version
          dv?: string // document version
          page_id?: string // document page id
        }
        const route = `/p/${projectId}/document/${documentId}/edit?${queryString.stringify({ ...searchParams, page_id: docPage.id })}`
        setLocation(route)

        setTimeout(() => {
          const pageId = docPage.id

          if (!pageId) return

          const page = document.getElementById(pageId)

          if (page) {
            page.scrollIntoView({
              block: 'center',
              inline: 'start',
            })
          }
        }, 0)
      }}
    >
      <span>Note {docPage.order_number}</span>
    </a>
  )
}
