import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog'
import { Switch, SwitchThumb } from '@/components/ui/switch'
import { Button } from '@/components/ui/button'
import {
  useGetTermsOfService,
  useAcceptTermsOfService,
} from '@/services/queries/termsOfService'

export const TermsOfServiceDialog = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const { data: termsOfService, isSuccess } = useGetTermsOfService()
  const { mutate: acceptTermsOfService } = useAcceptTermsOfService({
    onSuccess: () => {
      setIsOpen(false)
    },
  })

  useEffect(() => {
    if (isSuccess) {
      if (!termsOfService) {
        setIsOpen(true)
      }
    }
  }, [termsOfService, isSuccess])

  return (
    <Dialog open={isOpen}>
      <DialogContent withCloseButton={false}>
        <div className="flex flex-col items-center w-1/2 h-2/3 m-auto">
          <DialogTitle className="text-center">
            Quarter20 Terms of Service
          </DialogTitle>
          <div className="flex items-center flex-1 flex-col">
            <div className="flex items-center flex-1">
              <Switch onCheckedChange={setTermsAccepted}>
                <SwitchThumb />
              </Switch>
              <DialogDescription className="ml-3.5">
                I agree to Quarter20&apos;s{' '}
                <a
                  href="/static/terms_of_service.html"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-blue-800"
                >
                  Terms of service.
                </a>
              </DialogDescription>
            </div>
            <div className="flex">
              <Button
                className="flex items-center space-x-2"
                disabled={!termsAccepted}
                onClick={() => acceptTermsOfService()}
              >
                <span>Submit</span>
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
