import { useMemo } from 'react'

import { Editor, Range } from '@tiptap/react'
import { useToolsTable } from '@/services/hooks/template_attributes'
import type { EditorAttributes } from '@/components/editor/lib/types'

const MAX_BOM_ROWS = 3

interface AddPartFormProps {
  query: string
  editor: Editor
  range: Range
  updateBomOnSelect: boolean
}

export const AddToolForm = ({
  query,
  editor,
  updateBomOnSelect,
}: AddPartFormProps) => {
  const editorAttributes = editor.options.editorProps
    .attributes as EditorAttributes
  const documentPageId = editorAttributes.documentPageId

  const toolsTableTemplate = useToolsTable(documentPageId)
  const customTools = useMemo(
    () => toolsTableTemplate?.template_values.rows || [],
    [toolsTableTemplate?.template_values.rows],
  )

  const content = useMemo(
    () =>
      updateBomOnSelect ? (
        <>
          Add&nbsp;
          <span
            style={{
              padding: '2px 6px',
              borderRadius: '5px',
              backgroundColor: '#E5D5FF',
              color: 'rgb(30, 30, 30)',
            }}
          >
            {query}
          </span>
          &nbsp;to Tools BOM?
        </>
      ) : (
        <span
          style={{
            padding: '2px 6px',
            borderRadius: '5px',
            backgroundColor: '#E5D5FF',
            color: 'rgb(30, 30, 30)',
          }}
        >
          {query}
        </span>
      ),
    [updateBomOnSelect, query],
  )

  if (customTools?.length >= MAX_BOM_ROWS && updateBomOnSelect) {
    return <div>No result</div>
  }

  return (
    <div>
      <div className="px-2 py-2 break-all">{content}</div>
    </div>
  )
}
