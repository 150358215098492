import { useEffect, useMemo } from 'react'

import { useCADQuery } from '@/services/queries/cads'
import { useOperationSteps } from '@/services/queries/operation_steps'
import {
  countAllParts,
  findPartsInOperation,
} from '../fns/findPartsInOperation'

import type { BillOfMaterials, ToolsBillOfMaterials } from '@/state'
import {
  partsTableQuery,
  toolsTableQuery,
} from '@/services/hooks/template_attributes'
import { MUTATION_KEYS as TEMPLATE_ATTRIBUTES_MUTATION_KEYS } from '@/services/queries/template_attributes'
import { MUTATION_KEYS as CAD_VERSIONS_MUTATION_KEYS } from '@/services/queries/cad_versions'
import { useMutationState, useQuery } from '@tanstack/react-query'
import queryClient from '@/queryClient'

const TABLE_MUTATION_KEYS = [
  TEMPLATE_ATTRIBUTES_MUTATION_KEYS.CREATE_TABLE,
  TEMPLATE_ATTRIBUTES_MUTATION_KEYS.UPDATE_TABLE,
  TEMPLATE_ATTRIBUTES_MUTATION_KEYS.DELETE_TABLE,
  CAD_VERSIONS_MUTATION_KEYS.UPDATE_CAD_VERSION,
]

export const useStepBOM = ({
  documentPageId,
}: {
  documentPageId?: string | null
}): BillOfMaterials => {
  const stepId = documentPageId

  const { data: cadData } = useCADQuery()

  const { isLoading: isLoadingSteps, steps } = useOperationSteps()
  const assemblyTree = cadData.rawAssemblyTree

  if (!assemblyTree || isLoadingSteps || !steps || !stepId) {
    return []
  }

  const step = steps.find((step) => step.id === stepId)

  if (!step) {
    console.warn('Unable to find parts in operation: Operation step not found')
    return []
  }

  return findPartsInOperation(step, assemblyTree)
}

export const useFullPartsBOM = (): BillOfMaterials => {
  const { data: cadData } = useCADQuery()
  const { isLoading: isLoadingSteps, steps } = useOperationSteps()

  const assemblyTree = useMemo(() => cadData?.rawAssemblyTree, [cadData])

  const customPartsQuery = useQuery({
    queryKey: ['custom_parts'],
    enabled: !isLoadingSteps && !!steps?.length,
    queryFn: async () => {
      const partsTables = await Promise.all(
        steps.map((step) => partsTableQuery(step.id)),
      )

      const customParts = partsTables.map(
        (table) => table?.template_values.rows || [],
      )

      return customParts?.reduce((accum, pageCustomParts) => {
        pageCustomParts.forEach((part) => {
          const { partName, quantity } = part
          if (partName) {
            const finalQuantity =
              !quantity || isNaN(Number(quantity)) ? 0 : Number(quantity)
            if (accum[partName]) {
              accum[partName] += finalQuantity
            } else {
              accum[partName] = finalQuantity
            }
          }
        })
        return accum
      }, {} as any)
    },
  })

  const tableMutationData = useMutationState({
    filters: {
      status: 'success',
      predicate: (mutation) => {
        const [mutationKey] = mutation.options.mutationKey || []

        if (!mutationKey) return false

        return TABLE_MUTATION_KEYS.includes(mutationKey as any)
      },
    },
  })

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['custom_parts'],
    })
  }, [tableMutationData])

  if (!assemblyTree || isLoadingSteps || !steps) return []

  return countAllParts({ assemblyTree, customParts: customPartsQuery.data })
}

export const useFullToolsBOM = (): ToolsBillOfMaterials => {
  const { isLoading: isLoadingSteps, steps } = useOperationSteps()

  const customToolsQuery = useQuery({
    queryKey: ['custom_tools'],
    enabled: !isLoadingSteps && !!steps?.length,
    queryFn: async () => {
      const toolsTables = await Promise.all(
        steps.map((step) => toolsTableQuery(step.id)),
      )

      const customTools = toolsTables.map(
        (table) => table?.template_values.rows || [],
      )

      return customTools?.reduce((accum, pageCustomParts) => {
        pageCustomParts.forEach((tool) => {
          const { toolName } = tool
          const childInBom = accum.find((bom) => bom.toolName === toolName)
          if (!childInBom) {
            accum.push({ toolName })
          }
        })
        return accum
      }, [] as any)
    },
  })

  const tableMutationData = useMutationState({
    filters: {
      status: 'success',
      predicate: (mutation) => {
        const [mutationKey] = mutation.options.mutationKey || []

        if (!mutationKey) return false

        return TABLE_MUTATION_KEYS.includes(mutationKey as any)
      },
    },
  })

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['custom_tools'],
    })
  }, [tableMutationData])

  if (isLoadingSteps || !steps) return []

  return customToolsQuery.data
}
