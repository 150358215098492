import { useCallback } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Spinner } from '../ui/spinner'

type ConfirmationDialogProps = {
  title?: string
  children?: React.ReactNode
  isOpen?: boolean
  isLoading?: boolean
  closeDialog: () => void
  onConfirm: () => void
  onCancel?: () => void
}
export const ConfirmationDialog = ({
  title,
  children,
  isOpen,
  isLoading,
  closeDialog,
  onConfirm,
  onCancel,
}: ConfirmationDialogProps) => {
  const onConfirmHandler = useCallback(async () => {
    await onConfirm()
    closeDialog()
  }, [onConfirm, closeDialog])

  const onCancelHandler = useCallback(() => {
    onCancel && onCancel()
    closeDialog()
  }, [onCancel, closeDialog])

  return (
    <Dialog open={isOpen}>
      <DialogContent withCloseButton={false} className="max-w-lg min-h-0">
        <div className="flex flex-col">
          {title && (
            <DialogTitle className="text-center mt-2 mb-6">{title}</DialogTitle>
          )}
          <DialogDescription className="flex flex-1">
            {children}
          </DialogDescription>
          <div className="flex justify-center mt-6">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <Button
                  className="flex items-center mx-5"
                  variant="destructive"
                  onMouseDown={onConfirmHandler}
                >
                  <span>Confirm</span>
                </Button>
                <Button
                  className="flex items-center mx-5"
                  variant="secondary"
                  onMouseDown={onCancelHandler}
                >
                  <span>Cancel</span>
                </Button>
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
