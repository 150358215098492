import { useState, useEffect, useCallback, useMemo, ChangeEvent } from 'react'
import { ColumnDef } from '@tanstack/react-table'

import { TableCell } from '@/components/ui/table'
import {
  useToolsTable,
  useUpdateTable,
  useDeleteTable,
  useCreateTable,
  TableColumn,
  TableRow,
  useUpdateTemplateElement,
} from '@/services/hooks/template_attributes'
import type { DocumentPage } from '@/lib/api/client'
import { EditableTable, createNewColumn } from './EditableTable'
import { useDocumentState } from '@/state/document'

export type ToolsTableData = {
  toolName: string
  [accessor: string]: any
}

type ToolsTableProps = {
  documentPage: DocumentPage
  isReadOnly?: boolean
}
export const ToolsTable = ({
  documentPage,
  isReadOnly = false,
}: ToolsTableProps) => {
  const draggableId = useMemo(() => 'tools-table', [])
  const tableType = useMemo(() => 'tools-table', [])
  const initialColumnsDef = useMemo<ColumnDef<ToolsTableData>[]>(
    () => [
      {
        accessorKey: 'toolName',
        header: 'Tools',
        size: 200,
        cell: ({ getValue }) => (
          <TableCell className="flex flex-1 items-center border-x border-solid border-gray-200 border-collapse text-xs py-1 min-h-6">
            <span className="px-1">{getValue<string>()}</span>
          </TableCell>
        ),
      },
    ],
    [],
  )
  const initialTableData = useMemo<Array<ToolsTableData>>(
    () => [
      {
        toolName: '',
      },
    ],
    [],
  )
  const [tableData, setTableData] = useState<ToolsTableData[]>(() => [
    ...initialTableData,
  ])
  const [columnsDef, setColumnsDef] = useState<ColumnDef<ToolsTableData>[]>(
    () => [...initialColumnsDef],
  )
  const [columnsData, setColumnsData] = useState(
    initialColumnsDef.reduce((acc, column) => {
      acc[(column as any).accessorKey] = column.header
      return acc
    }, {}),
  )

  const toolsTableTemplate = useToolsTable(documentPage.id)
  const toolsColumns = useMemo(
    () => toolsTableTemplate?.template_values.columns,
    [toolsTableTemplate],
  )
  const toolsRows = useMemo(
    () => toolsTableTemplate?.template_values.rows,
    [toolsTableTemplate],
  )
  const tablePosition = useMemo(
    () => toolsTableTemplate?.template_values.position,
    [toolsTableTemplate?.template_values.position],
  )
  const columnsSize = useMemo(
    () => toolsTableTemplate?.template_values.columnsSize,
    [toolsTableTemplate?.template_values.columnsSize],
  )
  const { mutateAsync: updateTable } = useUpdateTable()
  const { mutateAsync: createTable } = useCreateTable()
  const { mutateAsync: deleteTable } = useDeleteTable()
  const { mutateAsync: updateTemplate } = useUpdateTemplateElement({
    documentPageId: documentPage.id as string,
  })

  const saveTableData = useCallback(
    ({
      columns,
      rows,
    }: {
      columns?: Array<TableColumn>
      rows?: Array<TableRow>
    }) => {
      if (toolsTableTemplate?.id) {
        updateTable({
          templateAttributeId: toolsTableTemplate?.id,
          documentPageId: documentPage.id as string,
          tableData: {
            type: tableType,
            columns,
            rows,
          },
        })
        return
      }

      createTable({
        documentPageId: documentPage.id as string,
        tableData: {
          type: tableType,
          columns,
          rows,
        },
      })
    },
    [
      documentPage.id,
      tableType,
      toolsTableTemplate?.id,
      updateTable,
      createTable,
    ],
  )

  const [isDeleting, setIsDeleting] = useState(false)

  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )

  const selectTable = useCallback(() => {
    setSelectedElement({
      documentPageId: documentPage.id as string,
      id: draggableId,
    })
  }, [documentPage.id, draggableId, setSelectedElement])

  const selectedElement = useDocumentState((state) => state.selectedElement)
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPage.id &&
      selectedElement?.id === draggableId,
    [selectedElement, documentPage.id, draggableId],
  )

  const deleteTableHandler = useCallback(async () => {
    setIsDeleting(true)
    if (toolsTableTemplate?.id) await deleteTable(toolsTableTemplate?.id)

    const cleanPayload = {
      removed: true,
    }
    await updateTemplate({
      payload: cleanPayload,
      data_type: tableType as any,
      templateAttributeId: toolsTableTemplate?.id as string,
    })
  }, [
    deleteTable,
    setIsDeleting,
    toolsTableTemplate?.id,
    tableType,
    updateTemplate,
  ])

  const onRemove = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        deleteTableHandler()
      }
    },
    [deleteTableHandler],
  )

  useEffect(() => {
    if (isSelected && selectedElement?.isRemovable) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected, selectedElement?.isRemovable])

  useEffect(() => {
    if (!tableData.length) {
      setTableData(() => [...initialTableData])
    }
  }, [tableData])

  useEffect(() => {
    if (toolsColumns) {
      setColumnsData((prev) => ({
        ...prev,
        ...toolsColumns.reduce(
          (acc, column) => ({
            ...acc,
            ...column,
          }),
          {},
        ),
      }))

      toolsColumns.forEach((column) => {
        Object.keys(column).forEach((accessorKey) => {
          const columnDef = createNewColumn(
            accessorKey,
            onCellChange,
            selectTable,
            isReadOnly,
            documentPage.id as string,
          ) as ColumnDef<ToolsTableData>
          setColumnsDef((prev) => [...prev, columnDef])
        })
      })
    }

    return () => {
      setColumnsDef(() => [...initialColumnsDef])
    }
  }, [toolsColumns])

  useEffect(() => {
    if (toolsRows?.length) {
      setTableData(() => [...(toolsRows as Array<ToolsTableData>)])
    }

    return () => {
      setTableData(() => [...initialTableData])
    }
  }, [toolsRows])

  const onCellChange = useCallback(
    (rowIndex, columnId, value) => {
      setTableData((prev) =>
        prev.map((row, index) =>
          index === rowIndex ? { ...row, [columnId]: value } : row,
        ),
      )
    },
    [setTableData],
  )

  const onHeaderChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, accessorKey: string) => {
      const target = event.target
      const value = target.value

      setColumnsData((prev) => ({
        ...prev,
        [accessorKey]: value,
      }))
    },
    [],
  )

  const saveToolsTableData = useCallback(() => {
    const cleanColumns = columnsDef
      .filter((column) =>
        ((column as any).accessorKey as string).includes('custom-column'),
      )
      .reduce((accum, colDef: any) => {
        const columnAccessor = Object.keys(columnsData).find(
          (accessorKey) => accessorKey === colDef.accessorKey,
        )

        if (!columnAccessor) return accum

        const column = columnsData[columnAccessor]

        return [
          ...accum,
          {
            [columnAccessor]: column,
          },
        ]
      }, [] as Array<any>)
    saveTableData({
      columns: cleanColumns,
      rows: tableData,
    })
  }, [tableData, columnsData, columnsDef, saveTableData])

  const addNewColumn = useCallback(
    ({
      prevAccessorKey,
      newColumn,
    }: {
      prevAccessorKey: string
      newColumn: ColumnDef<any>
    }) => {
      const accessorKey = (newColumn as { accessorKey: string }).accessorKey
      const columnBefore =
        columnsDef.findIndex(
          (column) => (column as any).accessorKey === prevAccessorKey,
        ) || columnsDef.length - 1

      setColumnsDef((prevColumns) => [
        ...prevColumns.slice(0, columnBefore + 1),
        newColumn as ColumnDef<ToolsTableData>,
        ...prevColumns.slice(columnBefore + 1),
      ])
      setTableData((prevTableData) =>
        prevTableData.map((row) => ({
          ...row,
          [accessorKey]: '',
        })),
      )
    },
    [columnsDef, setColumnsDef, setTableData],
  )

  const removeColumn = useCallback(
    (accessorKey: string) => {
      const columnBefore = columnsDef.findIndex(
        (column) => (column as any).accessorKey === accessorKey,
      )

      if (columnBefore === -1) return
      setColumnsDef((prevColumns) => [
        ...prevColumns.slice(0, columnBefore),
        ...prevColumns.slice(columnBefore + 1),
      ])
      setTableData((prevTableData) =>
        prevTableData.filter((row) => Object.keys(row).includes(accessorKey)),
      )
      setColumnsData((prev) => {
        const newColumns = { ...prev }
        delete newColumns[accessorKey]
        return newColumns
      })

      const filteredToolsRows = tableData
        .map((row) => {
          return Object.keys(row).reduce((acc, currAccessorKey) => {
            if ((currAccessorKey as string) !== accessorKey) {
              acc[currAccessorKey] = row[currAccessorKey]
            }

            return acc
          }, {})
        })
        .filter((row) => Object.keys(row).length)

      const filteredColumns = columnsDef
        .filter(
          (column) =>
            ((column as any).accessorKey as string).includes('custom-column') &&
            ((column as any).accessorKey as string) !== accessorKey,
        )
        .map((column) => {
          const accessorKey = (column as any).accessorKey
          return { [accessorKey]: columnsData[accessorKey] }
        })

      saveTableData({
        columns: filteredColumns,
        rows: filteredToolsRows,
      })
    },
    [columnsDef, columnsData, tableData, setColumnsDef, saveTableData],
  )

  const removeRow = useCallback(
    (rowIndex) => {
      const filteredRows = tableData.filter((_, index) => index !== rowIndex)
      setTableData((prev) => prev.filter((_, index) => index !== rowIndex))

      saveTableData({
        rows: filteredRows,
      })
    },
    [tableData, setTableData, saveTableData],
  )

  const addNewRow = useCallback((newRow: any) => {
    setTableData((prev) => [...prev, newRow])
  }, [])

  useEffect(() => {
    if (columnsSize) {
      setColumnsDef((prevColumns) =>
        prevColumns.map((column) => {
          const accessor = (column as any).accessorKey
          const size = (columnsSize as any)[accessor]
          if (size) {
            return {
              ...column,
              size,
            }
          }
          return column
        }),
      )
    }
  }, [columnsSize])

  const isToolsTableEmpty = tableData.every((row) => !row.toolName)

  if (isReadOnly && isToolsTableEmpty) {
    return null
  }

  return (
    !toolsTableTemplate?.template_values.hidden && (
      <EditableTable
        saveTableData={saveToolsTableData}
        onCellChange={onCellChange}
        removeRow={removeRow}
        addRow={addNewRow}
        onHeaderChange={onHeaderChange}
        saveCustomColumns={saveToolsTableData}
        removeColumn={removeColumn}
        addColumn={addNewColumn}
        documentPageId={documentPage?.id as string}
        tableData={tableData}
        defaultRows={0}
        defaultColumns={initialColumnsDef.length}
        columnsDef={columnsDef as any}
        columnsData={columnsData}
        positionData={tablePosition}
        columnsSize={columnsSize || {}}
        templateId={toolsTableTemplate?.id as string}
        isDeleting={isDeleting}
        isReadOnly={isReadOnly}
        id="tools-table"
        type="tools-table"
        direction="right"
        maxRows={4}
        maxColumns={2}
      />
    )
  )
}
