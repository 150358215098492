import { useState, useEffect } from 'react'
import { MinimalLayout } from '@/components/layouts/MinimalLayout'
import { EditorNavbar } from '@/components/nav/EditorNavbar'
import { WebWorkerDebugger } from '@/components/core/WebWorkerDebugger'
import { ReadOnlyBanner } from '@/components/banners/ReadOnlyBanner'
import { CadPageContainer } from '@/components/layouts/CadPageContainer'

import { CADEditor } from './components/CADEditor'
import { AssemblyOrderPanel } from './components/AssemblyOrderPanel'
import { AssemblyTree } from './components/AssemblyTree'
import { DraggableTreeNodeContext } from './components/AssemblyTree/DraggableTreeNodeContext'

export const CADPage = () => {
  const [showWebWorkerDebugger, setShowWebWorkerDebugger] =
    useState<boolean>(false)

  useEffect(() => {
    // @ts-expect-error - adding react-query devtools toogle
    window.toggleWebWorkerDebugger = () =>
      setShowWebWorkerDebugger((old) => !old)
  }, [])

  return (
    <MinimalLayout>
      <EditorNavbar active="cad" />
      <ReadOnlyBanner />
      <CadPageContainer>
        <DraggableTreeNodeContext>
          <Panel>
            <AssemblyTree />
          </Panel>
          <Panel>
            <AssemblyOrderPanel />
          </Panel>
        </DraggableTreeNodeContext>
        <CADEditor />
        {showWebWorkerDebugger && <WebWorkerDebugger />}
      </CadPageContainer>
    </MinimalLayout>
  )
}

const Panel = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className="my-4 mx-1 select-none"
      style={{
        maxWidth: 320,
        minWidth: 320,
        width: 320,
      }}
    >
      {children}
    </div>
  )
}
