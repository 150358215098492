import { ErrorBoundary } from '@sentry/react'
import dayjs from 'dayjs'

import type { View as ViewModel } from '@/lib/api/client'
import type { Step } from '@/services/queries/operation_steps/types'
import type { DocumentTypeChoices } from '@/lib/api/client'

import { Image } from '@/components/pdf/Image'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

export const Screenshot = ({
  view,
  steps,
  documentType,
  isReadOnly,
  onDelete,
  onClick,
}: {
  view: ViewModel
  steps: Step[]
  documentType: DocumentTypeChoices
  isReadOnly: boolean
  onDelete: () => void
  onClick: () => void
}) => {
  const downloadUrl = view.download_url

  if (!downloadUrl) {
    return null
  }

  const attachedStep = steps.find((step) => step.id === view.document_page)

  if (!attachedStep) {
    return null
  }

  return (
    <div className="max-w-lg flex flex-col border rounded-xl p-2">
      <ErrorBoundary fallback={<div>Failed to load image</div>}>
        <>
          <div className="flex items-center justify-between">
            <div>
              <div className="text-sm font-medium text-gray-900">
                {documentType === 'project_tracker' ? 'Note' : 'Step'}{' '}
                {attachedStep?.order_number}
              </div>
              <div className="text-xs text-gray-500">
                {dayjs(view.created).format('MMM DD, YYYY hh:mm A')}
              </div>
            </div>
            {!isReadOnly && (
              <DropdownMenu>
                <DropdownMenuTrigger className="px-1 py-2 rounded-lg hover:bg-gray-100">
                  <EllipsisVerticalIcon className="w-4 h-4 text-gray-500" />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onMouseDown={onDelete}>
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
          <Image
            src={downloadUrl}
            className="cursor-pointer"
            style={{
              height: '100%',
              width: 'auto',
              maxHeight: '100%',
            }}
            onClick={onClick}
          />
        </>
      </ErrorBoundary>
    </div>
  )
}
