export type IconProps = {
  className?: string
  id?: string
  strokeWidth?: number
  strokeColor?: string
}

export const ShapesIcon = ({
  className,
  id,
  strokeColor = '#1E1E1E',
}: IconProps) => {
  return (
    <svg
      width="52"
      height="44"
      viewBox="0 0 52 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        d="M10.5 22C10.5 26.9706 14.5294 31 19.5 31C24.4706 31 28.5 26.9706 28.5 22C28.5 17.0294 24.4706 13 19.5 13C14.5294 13 10.5 17.0294 10.5 22Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_903_7515)">
        <path
          d="M43.5 20L39 24.5L34.5 20"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_903_7515">
          <rect
            width="11"
            height="8"
            fill="white"
            transform="translate(33.5 18)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const LinesIcon = ({
  className,
  id,
  strokeColor = '#1E1E1E',
}: IconProps) => {
  return (
    <svg
      width="52"
      height="44"
      viewBox="0 0 52 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        d="M14.5 27L24.5 17M24.5 17H19M24.5 17V22.5"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_903_7520)">
        <path
          d="M43.5 20L39 24.5L34.5 20"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_903_7520">
          <rect
            width="11"
            height="8"
            fill="white"
            transform="translate(33.5 18)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const HardwareSpecificsIcon = ({
  className,
  id,
  strokeColor = '#1E1E1E',
}: IconProps) => {
  return (
    <svg
      width="52"
      height="44"
      viewBox="0 0 52 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <g clipPath="url(#clip0_903_10852)">
        <path
          d="M21.2114 25.1269V30.2577C21.2114 30.7531 21.2114 31.0005 21.3078 31.1897C21.3927 31.3561 21.5279 31.4918 21.6943 31.5766C21.8834 31.673 22.131 31.673 22.6254 31.673H27.7589C28.2534 31.673 28.5007 31.673 28.6897 31.5766C28.8561 31.4918 28.9918 31.3561 29.0766 31.1897C29.173 31.0007 29.173 30.7534 29.173 30.2589V25.1254C29.173 24.631 29.173 24.3834 29.0766 24.1943C28.9918 24.0279 28.8561 23.8927 28.6897 23.8078C28.5005 23.7114 28.2531 23.7114 27.7577 23.7114H22.6269C22.1315 23.7114 21.8836 23.7114 21.6943 23.8078C21.5279 23.8927 21.3927 24.0279 21.3078 24.1943C21.2114 24.3836 21.2114 24.6315 21.2114 25.1269Z"
          stroke={strokeColor}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip1_903_10852)">
        <path
          d="M9.82715 16.3077C9.82715 18.5062 11.6094 20.2884 13.8079 20.2884C16.0064 20.2884 17.7887 18.5062 17.7887 16.3077C17.7887 14.1092 16.0064 12.3269 13.8079 12.3269C11.6094 12.3269 9.82715 14.1092 9.82715 16.3077Z"
          stroke={strokeColor}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip2_903_10852)">
        <path
          d="M10.437 29.1076C10.0348 29.8043 9.83373 30.153 9.86378 30.4389C9.88998 30.6882 10.0208 30.9145 10.2237 31.0619C10.4561 31.2308 10.8581 31.2308 11.6621 31.2308H15.9538C16.7577 31.2308 17.1596 31.2308 17.3921 31.0619C17.5949 30.9145 17.7259 30.6882 17.7521 30.4389C17.7821 30.153 17.5811 29.8043 17.1788 29.1076L15.0338 25.3922C14.6315 24.6955 14.4303 24.3472 14.1677 24.2303C13.9387 24.1283 13.677 24.1283 13.448 24.2303C13.1855 24.3471 12.9844 24.6954 12.5825 25.3916L10.437 29.1076Z"
          stroke={strokeColor}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip3_903_10852)">
        <path
          d="M20.9175 15.5718C20.7789 15.4437 20.8542 15.212 21.0416 15.1898L23.697 14.8749C23.7734 14.8658 23.8397 14.8178 23.8719 14.748L24.992 12.3198C25.071 12.1485 25.3146 12.1484 25.3937 12.3198L26.5137 14.7479C26.5459 14.8178 26.6118 14.8659 26.6882 14.8749L29.3438 15.1898C29.5312 15.212 29.6062 15.4437 29.4677 15.5719L27.5047 17.3875C27.4482 17.4398 27.4231 17.5175 27.438 17.5929L27.959 20.2157C27.9958 20.4008 27.7988 20.5442 27.6341 20.452L25.3008 19.1456C25.2337 19.108 25.1522 19.1082 25.085 19.1458L22.7515 20.4517C22.5868 20.5439 22.3895 20.4008 22.4262 20.2157L22.9473 17.5931C22.9623 17.5177 22.9372 17.4397 22.8807 17.3875L20.9175 15.5718Z"
          stroke={strokeColor}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip4_903_10852)">
        <path
          d="M43.5 20L39 24.5L34.5 20"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_903_10852">
          <rect
            width="10.6154"
            height="10.6154"
            fill="white"
            transform="translate(19.8848 22.3846)"
          />
        </clipPath>
        <clipPath id="clip1_903_10852">
          <rect
            width="10.6154"
            height="10.6154"
            fill="white"
            transform="translate(8.5 11)"
          />
        </clipPath>
        <clipPath id="clip2_903_10852">
          <rect
            width="10.6154"
            height="10.6154"
            fill="white"
            transform="translate(8.5 22.3848)"
          />
        </clipPath>
        <clipPath id="clip3_903_10852">
          <rect
            width="10.6154"
            height="10.6154"
            fill="white"
            transform="translate(19.8848 11)"
          />
        </clipPath>
        <clipPath id="clip4_903_10852">
          <rect
            width="11"
            height="8"
            fill="white"
            transform="translate(33.5 18)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const TextIcon = ({
  className,
  id,
  strokeColor = '#1E1E1E',
}: IconProps) => (
  <svg
    width="52px"
    height="52px"
    viewBox="-7 -7 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    id={id}
  >
    <path
      d="M9 9H15"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12 15L12 9"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6 4C6 5.10457 5.10457 6 4 6C2.89543 6 2 5.10457 2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4Z"
      stroke={strokeColor}
      strokeWidth="1.5"
    />
    <path
      d="M6 20C6 21.1046 5.10457 22 4 22C2.89543 22 2 21.1046 2 20C2 18.8954 2.89543 18 4 18C5.10457 18 6 18.8954 6 20Z"
      stroke={strokeColor}
      strokeWidth="1.5"
    />
    <path
      d="M22 4C22 5.10457 21.1046 6 20 6C18.8954 6 18 5.10457 18 4C18 2.89543 18.8954 2 20 2C21.1046 2 22 2.89543 22 4Z"
      stroke={strokeColor}
      strokeWidth="1.5"
    />
    <path
      d="M22 20C22 21.1046 21.1046 22 20 22C18.8954 22 18 21.1046 18 20C18 18.8954 18.8954 18 20 18C21.1046 18 22 18.8954 22 20Z"
      stroke={strokeColor}
      strokeWidth="1.5"
    />
    <path
      d="M18 4H6"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20 18L20 12M20 6V8"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18 20L12 20M6 20L8 20"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4 6L4 18"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export const CircleIcon = ({
  className,
  id,
  strokeWidth = 1,
  strokeColor,
}: IconProps) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        d="M1.5 10C1.5 14.9706 5.52944 19 10.5 19C15.4706 19 19.5 14.9706 19.5 10C19.5 5.02944 15.4706 1 10.5 1C5.52944 1 1.5 5.02944 1.5 10Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const TriangleIcon = ({
  className,
  id,
  strokeWidth = 1,
  strokeColor,
}: IconProps) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        d="M1.85955 14.9571L9.29344 2.79256C10.0733 1.51643 11.9267 1.51643 12.7066 2.79256L20.1405 14.9571C20.9549 16.2898 19.9957 18 18.4339 18H3.56611C2.00425 18 1.04512 16.2898 1.85955 14.9571Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SquareIcon = ({
  className,
  id,
  strokeWidth = 1,
  strokeColor,
}: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="2.5 2.5 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="1"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export const TorxIcon = ({ className, id, strokeColor }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99998 0C4.48071 0 0 4.48085 0 10C0 15.5191 4.48071 20 9.99998 20C15.5193 20 20 15.5191 20 10C20 4.48085 15.5193 0 9.99998 0ZM9.99998 1.18536C5.13499 1.18536 1.185 5.13528 1.185 9.99986C1.185 14.8647 5.13499 18.8148 9.99998 18.8148C14.865 18.8148 18.815 14.8647 18.815 9.99986C18.815 5.13491 14.865 1.18536 9.99998 1.18536Z"
        fill={strokeColor}
      />
      <path
        d="M11.6374 6.45692C11.7082 6.61984 11.878 6.71651 12.0543 6.69427L15.2858 6.28656C15.6278 6.24341 15.8606 6.62421 15.6663 6.90893L13.7427 9.72703C13.6479 9.86583 13.65 10.0491 13.748 10.1856L15.7334 12.9544C15.9341 13.2344 15.7107 13.6202 15.3679 13.5855L12.1289 13.257C11.9525 13.2391 11.7854 13.3395 11.7183 13.5037L10.4441 16.622C10.3102 16.9496 9.84819 16.9548 9.70702 16.6303L8.365 13.5452C8.29415 13.3824 8.12438 13.2857 7.94816 13.3079L4.71544 13.7156C4.3734 13.7588 4.14065 13.3779 4.33506 13.0932L6.25886 10.2759C6.35367 10.1371 6.35154 9.95381 6.25355 9.81721L4.26678 7.04773C4.06599 6.76783 4.28934 6.38193 4.63207 6.41661L7.87432 6.74466C8.05077 6.76251 8.2179 6.66205 8.28492 6.49786L9.55814 3.37873C9.69192 3.05099 10.1541 3.04577 10.2953 3.3704L11.6374 6.45692Z"
        fill={strokeColor}
      />
    </svg>
  )
}

export const PhillipsIcon = ({ className, id, strokeColor }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        d="M8.48763 11.8519C8.48763 11.631 8.30855 11.4519 8.08763 11.4519H4.4C4.17909 11.4519 4 11.2729 4 11.0519V8.94682C4 8.72591 4.17909 8.54682 4.4 8.54682H8.08763C8.30854 8.54682 8.48763 8.36773 8.48763 8.14682V4.4C8.48763 4.17909 8.66672 4 8.88763 4H11.1105C11.3314 4 11.5105 4.17909 11.5105 4.4V8.14682C11.5105 8.36773 11.6896 8.54682 11.9105 8.54682H15.6C15.8209 8.54682 16 8.7259 16 8.94682V11.0519C16 11.2729 15.8209 11.4519 15.6 11.4519H11.9105C11.6896 11.4519 11.5105 11.631 11.5105 11.8519V15.6C11.5105 15.8209 11.3315 16 11.1105 16H8.88763C8.66672 16 8.48763 15.8209 8.48763 15.6V11.8519Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0008 9.99964C20.0008 4.48051 15.5195 0 10.0004 0C4.48124 0 0 4.48051 0 9.99964C0 15.5195 4.48124 20 10.0004 20C15.5195 20 20.0008 15.5195 20.0008 9.99964ZM18.8148 9.99964C18.8148 5.13483 14.8652 1.18504 10.0005 1.18504C5.13565 1.18504 1.18586 5.13519 1.18586 9.99964C1.18586 14.8649 5.136 18.8146 10.0005 18.8146C14.8652 18.8146 18.8148 14.8649 18.8148 9.99964Z"
        fill={strokeColor}
      />
    </svg>
  )
}

export const HexIcon = ({ className, id, strokeColor }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99997 0C4.48071 0 0 4.48085 0 10C0 15.5191 4.48071 20 9.99997 20C15.5193 20 20 15.5191 20 10C20 4.48085 15.5193 0 9.99997 0ZM9.99997 1.18536C5.13464 1.18536 1.185 5.13527 1.185 9.99986C1.185 14.8647 5.13499 18.8148 9.99997 18.8148C14.865 18.8148 18.815 14.8647 18.815 9.99986C18.815 5.13491 14.865 1.18536 9.99997 1.18536Z"
        fill={strokeColor}
      />
      <path
        d="M12.4276 5.0005C12.7787 5.00019 13.1042 5.18398 13.2852 5.48474L15.6903 9.47975C15.8809 9.79639 15.8813 10.1924 15.6914 10.5094L13.2948 14.51C13.1143 14.8113 12.7889 14.9958 12.4377 14.9961L7.57141 14.9996C7.22036 14.9998 6.89489 14.816 6.71388 14.5152L4.30946 10.52C4.11899 10.2035 4.1185 9.80774 4.3082 9.49077L6.70183 5.4913C6.88225 5.18982 7.20766 5.00515 7.559 5.00484L12.4276 5.0005Z"
        fill={strokeColor}
      />
    </svg>
  )
}

export const PozidrivIcon = ({ className, id, strokeColor }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0018 10C20.0018 4.48071 15.5208 0 10.0011 0C4.48143 0 0 4.48071 0 10C0 15.5192 4.48108 20 10.0011 20C15.5208 20 20.0018 15.5192 20.0018 10ZM18.8164 10C18.8164 5.13536 14.866 1.18571 10.001 1.18571C5.13567 1.18571 1.18568 5.13536 1.18568 10C1.18568 14.8646 5.13567 18.8143 10.001 18.8143C14.8664 18.8143 18.8164 14.8646 18.8164 10Z"
        fill={strokeColor}
      />
      <path
        d="M11.6365 7.84956L14.0892 5.90343C14.1824 5.82948 14.3037 5.95101 14.2296 6.04407L12.235 8.54821H15.725C15.9459 8.54821 16.125 8.72729 16.125 8.94821V11.0527C16.125 11.2736 15.9459 11.4527 15.725 11.4527H12.2362L14.2301 13.9552C14.3043 14.0483 14.183 14.1698 14.0898 14.0959L11.6363 12.1496V15.6C11.6363 15.8209 11.4572 16 11.2363 16H9.01348C8.79256 16 8.61348 15.8209 8.61348 15.6V12.1505L6.16125 14.0965C6.06805 14.1705 5.94673 14.0489 6.02087 13.9559L8.01528 11.4525H4.525C4.30409 11.4525 4.125 11.2734 4.125 11.0525V8.94803C4.125 8.72711 4.30409 8.54803 4.525 8.54803H8.01621L6.02075 6.04287C5.94663 5.94981 6.06792 5.82828 6.16112 5.90222L8.6136 7.84796V4.4C8.6136 4.17909 8.79268 4 9.0136 4H11.2364C11.4573 4 11.6364 4.17908 11.6364 4.39999L11.6365 7.84956Z"
        fill={strokeColor}
      />
    </svg>
  )
}

export const CheckmarkIcon = ({ className, id, strokeColor }: IconProps) => {
  return (
    <svg
      width="21"
      height="16"
      viewBox="-1 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        d="M18.9894 0.137311C18.9118 0.0611527 18.7875 0.0610183 18.7097 0.137008L6.16572 12.3857C6.09247 12.4572 5.97703 12.4618 5.89825 12.3965L1.8584 9.04303C1.7749 8.97372 1.65136 8.98365 1.57999 9.0654L0.134848 10.7208C0.0609238 10.8055 0.0712857 10.9345 0.157784 11.0063L5.25657 15.2381L6.04613 15.8863C6.12494 15.951 6.23981 15.9461 6.31276 15.8748L20.5597 1.96342C20.6399 1.88506 20.6401 1.75605 20.56 1.67753L18.9894 0.137311Z"
        fill={strokeColor}
      />
    </svg>
  )
}

export const XIcon = ({ className, id, strokeColor }: IconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="-1.5 -1.5 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.49809L1.49809 0L7.99003 6.49194L14.5019 0L16 1.49809L9.48812 7.99003L16 14.5019L14.5019 16L7.99003 9.48812L1.49809 16L0 14.5019L6.49194 7.99003L0 1.49809Z"
        fill={strokeColor}
      />
    </svg>
  )
}

export const FireHazardIcon = ({ className, id, strokeColor }: IconProps) => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.706476 16C0.395558 16 0.203513 15.6608 0.363479 15.3942L9.257 0.571661C9.41236 0.312725 9.78764 0.312726 9.943 0.571662L18.8365 15.3942C18.9965 15.6608 18.8044 16 18.4935 16H0.706476ZM1.86681 14.9334H17.3335L9.60014 1.86676L1.86681 14.9334ZM12.2668 12.8C12.2668 12.8 11.7335 14.1334 10.6667 14.4001C10.6667 14.4001 11.7333 13.0668 10.9335 12.0002C10.9335 12.0002 10.1334 12.267 10.1334 12.8003C10.1334 12.8003 9.06684 12.0002 10.1334 11.2002C10.1334 11.2002 10.6667 9.86686 9.60012 9.86686C9.60012 9.86686 9.78874 10.2117 9.60012 10.4001C9.33338 10.6669 7.20022 12.0002 8.53355 13.0668C8.53355 13.0668 8.26681 13.3336 7.7335 13.0668C7.7335 13.0668 8.00025 13.6001 8.53355 14.4001C8.53355 14.4001 6.40017 13.8669 6.40017 11.7335C6.40017 11.7335 6.40017 10.6669 7.46674 9.86686C7.46674 9.86686 8.26679 10.4001 8.80008 10.1336C8.80008 10.1336 9.06682 9.86686 8.80008 9.06703C8.80008 9.06703 8.26679 7.73369 9.60012 7.20041C9.60012 7.20041 9.33338 8.00046 9.86687 8.53374L11.467 10.1338C11.467 10.1338 12.5335 10.9334 12.2668 12.8Z"
        fill={strokeColor}
      />
    </svg>
  )
}

export const WarningIcon = ({ className, id, strokeColor }: IconProps) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        d="M10.5761 7.08998L10.2344 11.3512C10.2143 11.6729 9.91269 11.9341 9.55095 11.9341C9.18921 11.9341 8.90766 11.6929 8.86757 11.3512L8.52588 7.08998C8.48564 6.54723 8.94806 6.08496 9.55097 6.08496C10.1338 6.10501 10.6163 6.5673 10.5761 7.08998Z"
        fill={strokeColor}
      />
      <path
        d="M9.54628 12.5977C10.0688 12.5977 10.491 13.0199 10.491 13.5424C10.491 14.065 10.0688 14.4871 9.54628 14.4871C9.02374 14.4871 8.60156 14.065 8.60156 13.5424C8.60156 13.0399 9.02374 12.5977 9.54628 12.5977Z"
        fill={strokeColor}
      />
      <path
        d="M0.120522 14.7336L8.84412 0.401973C9.00494 0.160811 9.26631 0 9.54771 0C9.84916 0 10.1105 0.140771 10.2513 0.401973L18.9749 14.7336C19.1357 14.9949 19.1357 15.3164 18.9949 15.5778C18.8542 15.8392 18.5728 16 18.2713 16H0.844233C0.542783 16 0.261391 15.8392 0.120616 15.5778C-0.0402054 15.3165 -0.0402994 14.9948 0.120522 14.7336ZM17.7888 14.8943L9.54764 1.34657L1.30645 14.8943H17.7888Z"
        fill={strokeColor}
      />
    </svg>
  )
}

export const LineIcon = ({
  className,
  id,
  strokeWidth = 1,
  strokeColor,
}: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        d="M7 17L17 7"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ArrowIcon = ({
  className,
  id,
  strokeWidth = 1,
  strokeColor,
}: IconProps) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        d="M7.5 17L17.5 7M17.5 7H12M17.5 7V12.5"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ArrowWithMiddleTextIcon = ({
  className,
  id,
  strokeWidth = 1,
  strokeColor,
}: IconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    id={id}
  >
    <path
      d="M1 13L4 10"
      stroke={strokeColor}
      strokeWidth={strokeWidth - 1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 4L13 1"
      stroke={strokeColor}
      strokeWidth={strokeWidth - 1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask id="arrow-with-middle-text-icon" fill="white">
      <rect x="5" y="5" width="4" height="4" rx="1" />
    </mask>
    <rect
      x="5"
      y="5"
      width="4"
      height="4"
      rx="1"
      stroke={strokeColor}
      strokeWidth={strokeWidth - 0.2}
      strokeLinejoin="round"
      mask="url(#arrow-with-middle-text-icon)"
    />
  </svg>
)

export const ArrowWithBottomTextIcon = ({
  className,
  id,
  strokeWidth = 1,
  strokeColor,
}: IconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    id={id}
  >
    <path
      d="M 6 7 L 1 12"
      stroke={strokeColor}
      strokeWidth={strokeWidth - 1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask id="arrow-with-bottom-text-icon" fill="white">
      <rect x="7" y="2" width="4" height="4" rx="1" />
    </mask>
    <rect
      x="7"
      y="2"
      width="4"
      height="4"
      rx="1"
      stroke={strokeColor}
      strokeWidth={strokeWidth - 0.2}
      strokeLinejoin="round"
      mask="url(#arrow-with-bottom-text-icon)"
    />
  </svg>
)
