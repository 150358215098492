import {
  useState,
  Fragment,
  useEffect,
  useCallback,
  useMemo,
  ChangeEvent,
  useRef,
  InputHTMLAttributes,
  ReactNode,
} from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  Header,
  CellContext,
  Row,
  Column,
} from '@tanstack/react-table'
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { useDraggable } from '@dnd-kit/core'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Input } from '@/components/ui/input'
import { cn } from '@/utils'
import { useAnnotationsQuery } from './Annotations/queries'
import { useDocumentState } from '@/state/document'
import { Spinner } from '@/components/ui/spinner'
import { usePrevious } from '@/utils/hooks'
import { useUpdateTemplateElement } from '@/services/hooks/template_attributes'

export const createNewColumn = (
  accessorKey: string,
  onCellChange: (
    rowIndex: number,
    columnId: string | number,
    value: string,
  ) => void,
  selectTable: () => void,
  isReadOnly: boolean,
  documentPageId: string,
): ColumnDef<TableData> => ({
  accessorKey,
  header: '',
  enableResizing: true,
  size: 100,
  cell: (cellProps) => (
    <EditableTableCell
      {...cellProps}
      isReadOnly={isReadOnly}
      documentPageId={documentPageId}
      onChange={onCellChange}
      selectTable={selectTable}
    />
  ),
})

type TableData = {
  [accessor: string]: any
}
type ColumnsData = {
  [accessor: string]: any
}
type EditableTableProps = {
  saveTableData: ({ rowIndex, isCustomRow }) => void
  onCellChange: (
    rowIndex: number,
    columnId: string | number,
    value: string,
  ) => void
  removeRow?: (rowIndex: number) => void
  addRow?: (newRow: TableData) => void
  onHeaderChange: (
    event: ChangeEvent<HTMLInputElement>,
    accessorKey: string,
  ) => void
  saveCustomColumns: () => void
  removeColumn?: (accessorKey: string) => void
  addColumn?: ({
    prevAccessorKey,
    newColumn,
  }: {
    prevAccessorKey: string
    newColumn: ColumnDef<TableData>
  }) => void
  documentPageId: string
  tableData: Array<TableData>
  defaultRows?: number
  columnsDef: Array<ColumnDef<TableData>>
  defaultColumns?: number
  columnsData: ColumnsData
  tableClasses?: string
  maxRows?: number
  maxColumns?: number
  additionalColumns?: Array<ReactNode>
  id: string
  isReadOnly?: boolean
  type: string
  positionData?: { x: number; y: number }
  isDeleting?: boolean
  direction?: 'right' | 'left'
  columnsSize: {
    [accessor: string]: number
  }
  templateId: string
}
export const EditableTable = ({
  saveTableData,
  onCellChange,
  removeRow,
  addRow,
  onHeaderChange,
  saveCustomColumns,
  removeColumn,
  addColumn,
  documentPageId,
  tableData,
  defaultRows = tableData.length,
  columnsDef,
  defaultColumns = columnsDef.length,
  columnsData,
  tableClasses = '',
  maxRows = 4,
  maxColumns = 4,
  additionalColumns = [],
  id,
  isReadOnly = false,
  type,
  positionData,
  isDeleting = false,
  direction = 'left',
  columnsSize,
  templateId,
}: EditableTableProps) => {
  const INITIAL_DELTA = useMemo(() => ({ x: 0, y: 0 }), [])
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const zoom = useDocumentState((state) => state.zoom)
  const zoomRatio = useMemo(() => zoom / 100, [zoom])
  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPageId &&
      selectedElement?.id === id,
    [selectedElement, documentPageId, id],
  )
  const [isHoveringTable, setIsHoveringTable] = useState<boolean>(false)
  const [{ position, delta }, setPosition] = useState({
    position: positionData,
    delta: INITIAL_DELTA,
  })

  const tableRef = useRef<HTMLTableElement>(null)

  const selectTable = useCallback(() => {
    setSelectedElement({ documentPageId, id, isRemovable: false })
  }, [documentPageId, id, setSelectedElement])

  const { isAddingAnnotation } = useAnnotationsQuery()
  const { mutateAsync: updateTemplate } = useUpdateTemplateElement({
    documentPageId,
  })

  const {
    attributes,
    isDragging,
    listeners,
    transform,
    setNodeRef,
    setActivatorNodeRef,
  } = useDraggable({
    disabled: isAddingAnnotation || isReadOnly,
    id: templateId || id,
    data: { type, data_type: 'table' },
  })

  const canAddNewColumn = useMemo(
    () => columnsDef.length < maxColumns,
    [columnsDef.length, maxColumns],
  )

  const canAddNewRow = useMemo(
    () => tableData.length < maxRows,
    [tableData.length, maxRows],
  )

  useEffect(() => {
    const tableContainer = tableRef.current
    if (tableContainer) {
      setNodeRef(tableContainer)
      tableContainer.addEventListener('mouseenter', () =>
        setIsHoveringTable(true),
      )
      tableContainer.addEventListener('mouseleave', () =>
        setIsHoveringTable(false),
      )
    }
  }, [tableRef, setNodeRef])

  useEffect(() => {
    if (!(transform?.x || transform?.y)) return
    setPosition((prevPosition) => ({
      ...prevPosition,
      delta: {
        x: (transform?.x || 0) / zoomRatio,
        y: (transform?.y || 0) / zoomRatio,
      },
    }))
  }, [transform, zoomRatio])

  useEffect(() => {
    setPosition({
      position: positionData,
      delta: INITIAL_DELTA,
    })
  }, [INITIAL_DELTA, positionData])

  const getNewColumnAccessor = useCallback(() => {
    const accessor = `custom-column_${documentPageId}_${Math.floor(
      Math.random() * 1000,
    )}`
    if (columnsData[accessor]) return getNewColumnAccessor()
    return accessor
  }, [columnsData, documentPageId])

  const addNewColumn = useCallback(
    (prevAccessorKey: string) => {
      if (!(canAddNewColumn && addColumn)) return

      const accessorKey = getNewColumnAccessor()
      const newColumn = createNewColumn(
        accessorKey,
        onCellChange,
        selectTable,
        isReadOnly,
        documentPageId,
      )
      addColumn({ prevAccessorKey, newColumn })
    },
    [
      documentPageId,
      canAddNewColumn,
      isReadOnly,
      addColumn,
      getNewColumnAccessor,
      onCellChange,
      selectTable,
    ],
  )

  const addNewRow = useCallback(() => {
    if (!(canAddNewRow && addRow)) return
    const newRow = columnsDef.reduce((acc, column) => {
      const accessorKey = (column as { accessorKey: string }).accessorKey
      acc[accessorKey] = ''
      return acc
    }, {}) as TableData

    addRow(newRow)
  }, [canAddNewRow, columnsDef, addRow])

  const onResizeHandler = useCallback(
    async ({
      width,
      accessor,
    }: {
      width?: number | string
      accessor: string
    }) => {
      await updateTemplate({
        payload: {
          type,
          columnsSize: {
            ...(columnsSize || {}),
            [accessor]: width as number,
          },
        },
        data_type: 'table' as any,
        templateAttributeId: templateId,
      })
    },
    [columnsSize, type, templateId, updateTemplate],
  )

  const renderEditableTableHeader = useCallback(
    (header: Header<TableData, unknown>, index: number) => {
      const accessorKey = (header.column.columnDef as { accessorKey: string })
        .accessorKey
      const isCustomColumn = accessorKey.includes('custom-column')
      const inputProps = {
        disabled: true,
        value: columnsData[accessorKey] || '',
        className: 'border-none shadow-none m-0 px-1 py-0 h-full text-xs',
        style: {
          opacity: 1,
          cursor: 'default',
        },
      }

      if (isCustomColumn) {
        inputProps['disabled'] = false
        inputProps['onChange'] = (event) => onHeaderChange(event, accessorKey)
        inputProps['style'] = {
          ...inputProps.style,
          cursor: 'text',
        }
      }

      return (
        <EditableTableHeader
          key={header.id}
          inputProps={inputProps}
          canAddNewColumn={index >= defaultColumns - 1 && canAddNewColumn}
          canRemoveColumn={isCustomColumn}
          saveChanges={saveCustomColumns}
          columnAccessor={accessorKey}
          isDragging={isDragging}
          removeColumn={removeColumn}
          addNewColumn={addNewColumn}
          onResizeEnd={onResizeHandler}
          selectTable={selectTable}
          isReadOnly={isReadOnly}
          width={header.getSize()}
          isResizing={header.column.getIsResizing()}
          resizeHandler={header.getResizeHandler()}
          canResize={header.column.getCanResize() && !isReadOnly}
          direction={direction}
        />
      )
    },
    [
      onHeaderChange,
      columnsData,
      canAddNewColumn,
      defaultColumns,
      isDragging,
      isReadOnly,
      direction,
      onResizeHandler,
      removeColumn,
      addNewColumn,
      saveCustomColumns,
      selectTable,
    ],
  )

  const table = useReactTable({
    data: tableData,
    columns: columnsDef,
    columnResizeMode: 'onChange',
    columnResizeDirection: direction === 'right' ? 'rtl' : 'ltr',
    getCoreRowModel: getCoreRowModel(),
    meta: {
      saveTableData,
      tableId: id,
    },
  })

  return (
    <div
      ref={setActivatorNodeRef}
      {...attributes}
      {...listeners}
      className={cn('border box-content p-px absolute', {
        'cursor-move': !isReadOnly,
        'cursor-default': isReadOnly,
        'rounded-md': isSelected,
        'shadow-md': isDragging,
        'shadow-gray-200': isDragging,
        'bg-sky-200/10': isDragging,
        'z-[1]': !isDragging,
        'z-[100]': isDragging,
      })}
      style={{
        translate: `${delta?.x || 0}px ${delta?.y || 0}px`,
        top: position?.y || 0,
        [direction]:
          direction === 'right' ? -(position?.x || 0) : position?.x || 0,
        borderColor: isSelected ? '#818cf8' : 'transparent',
      }}
    >
      {isDeleting && (
        <div className="absolute h-full w-full bg-zinc-400/60 z-10">
          <Spinner />
        </div>
      )}
      <Table
        overflow="visible"
        ref={tableRef}
        className={cn(
          'border-y border-solid border-gray-200 border-collapse relative',
          tableClasses,
          {
            'pointer-events-none': isAddingAnnotation,
            'pointer-events-auto': !isAddingAnnotation,
          },
        )}
      >
        <TableHeader className="bg-muted/80">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className="w-full">
              {additionalColumns.map((column, i) => (
                <Fragment key={`additional-column-${i}`}>{column}</Fragment>
              ))}
              {headerGroup.headers.map(renderEditableTableHeader)}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row, i) => (
            <DeletableRow
              row={row}
              removeRow={removeRow}
              isDragging={isDragging}
              key={row.id}
              onCellChange={onCellChange}
              isCustomRow={i >= defaultRows}
              renderAdditionalColumn={!!additionalColumns.length}
              selectTable={selectTable}
              documentPageId={documentPageId}
              isReadOnly={isReadOnly}
            />
          ))}
          {canAddNewRow && isHoveringTable && !isDragging && !isReadOnly && (
            <TableRow className="absolute -bottom-[15px]">
              <TableCell>
                <div className="absolute flex flex-1 right-1 top-1 cursor-pointer">
                  <PlusCircleIcon
                    className="h-4 w-4 text-blue-400 cursor-pointer"
                    onClick={addNewRow}
                    onMouseDownCapture={(e) => e.stopPropagation()}
                  />
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

type DeletableRowProps = {
  isCustomRow: boolean
  renderAdditionalColumn?: boolean
  row: Row<TableData>
  isDragging?: boolean
  isReadOnly?: boolean
  removeRow?: (index: number) => void
  onCellChange: (
    rowIndex: number,
    columnId: string | number,
    value: string,
  ) => void
  selectTable?: () => void
  documentPageId: string
}
const DeletableRow = ({
  row,
  isCustomRow,
  renderAdditionalColumn,
  isDragging,
  isReadOnly,
  removeRow,
  onCellChange,
  selectTable,
  documentPageId,
}: DeletableRowProps) => {
  const rowRef = useRef<HTMLTableRowElement>(null)
  const optionsRef = useRef<HTMLTableRowElement>(null)
  const [isHoveringRow, setIsHoveringRow] = useState(false)
  const [isHoveringOptions, setIsHoveringOptions] = useState(false)

  useEffect(() => {
    if (rowRef.current && isCustomRow) {
      rowRef.current.addEventListener('mouseenter', () => {
        setIsHoveringRow(true)
      })
      rowRef.current.addEventListener('mouseleave', () => {
        setTimeout(() => {
          setIsHoveringRow(false)
        }, 200)
      })
    }

    if (optionsRef.current && isCustomRow) {
      optionsRef.current.addEventListener('mouseenter', () => {
        setIsHoveringOptions(true)
      })
      optionsRef.current.addEventListener('mouseleave', () => {
        setIsHoveringOptions(false)
      })
    }
  }, [rowRef, isCustomRow])

  return (
    <Fragment>
      <tr className="relative border-0 p-0 block" ref={optionsRef}>
        {isCustomRow &&
          (isHoveringRow || isHoveringOptions) &&
          removeRow &&
          !isDragging &&
          !isReadOnly && (
            <td
              className="absolute -left-[18px] border-0 p-0 h-full flex items-center pr-2"
              style={{
                top: 13,
              }}
            >
              <XCircleIcon
                className="h-4 w-4 text-red-500 cursor-pointer"
                onClick={() => removeRow(row.index)}
                onMouseDownCapture={(e) => e.stopPropagation()}
              />
            </td>
          )}
      </tr>
      <TableRow key={row.id} className="w-full relative" ref={rowRef}>
        {renderAdditionalColumn && (
          <TableCell
            className="border-x border-solid border-gray-200 border-collapse py-0 relative bg-white/70"
            style={{
              width: 35,
            }}
          >
            <span className="flex border border-black shadow-none m-0 text-xs h-4 w-4 rounded-full left-0 right-0 m-auto justify-center items-center text-xs p-2">
              {String.fromCharCode(65 + row.index)}
            </span>
          </TableCell>
        )}
        {row.getVisibleCells().map((cell) => (
          <Fragment key={cell.id}>
            {isCustomRow ? (
              <EditableTableCell
                {...cell.getContext()}
                onChange={onCellChange}
                selectTable={selectTable}
                isCustomRow={isCustomRow}
                isReadOnly={isReadOnly}
                documentPageId={documentPageId}
              />
            ) : (
              flexRender(cell.column.columnDef.cell, cell.getContext())
            )}
          </Fragment>
        ))}
      </TableRow>
    </Fragment>
  )
}

type EditableTableHeaderProps = {
  inputProps: InputHTMLAttributes<HTMLInputElement>
  canAddNewColumn: boolean
  canRemoveColumn: boolean
  columnAccessor: string
  isDragging?: boolean
  isReadOnly?: boolean
  width?: number | string
  isResizing?: boolean
  canResize?: boolean
  direction: 'left' | 'right'
  onResizeEnd?: ({
    width,
    accessor,
  }: {
    width?: number | string
    accessor: string
  }) => void
  resizeHandler?: (event: unknown) => void
  saveChanges: () => void
  removeColumn?: (columnAccessor: string) => void
  addNewColumn?: (columnAccessor: string) => void
  selectTable?: () => void
}
const EditableTableHeader = ({
  inputProps,
  canAddNewColumn,
  canRemoveColumn,
  columnAccessor,
  isDragging,
  isReadOnly,
  width,
  isResizing,
  canResize,
  direction,
  resizeHandler,
  onResizeEnd,
  saveChanges,
  removeColumn,
  addNewColumn,
  selectTable,
}: EditableTableHeaderProps) => {
  const [hasChanged, setHasChanged] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const inputRef = useRef<HTMLTableCellElement>(null)
  const { onChange, ...otherProps } = useMemo(() => inputProps, [inputProps])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('mouseenter', () => {
        setIsHovering(true)
      })
      inputRef.current.addEventListener('mouseleave', () => {
        setIsHovering(false)
      })
    }
  }, [inputRef])

  const handleOnBlur = () => {
    if (hasChanged) {
      saveChanges()
      setHasChanged(false)
    }
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHasChanged(true)
    if (onChange) {
      onChange(event)
    }
  }

  const prevIsResizing = usePrevious(isResizing)

  useEffect(() => {
    if (!onResizeEnd) return
    if (prevIsResizing && !isResizing) {
      onResizeEnd({ width, accessor: columnAccessor })
    }
  }, [isResizing, width, columnAccessor, prevIsResizing, onResizeEnd])

  return (
    <TableHead
      className="relative items-center border-x border-solid border-gray-200 border-collapse text-xs h-auto py-1"
      ref={inputRef}
      style={{ position: 'relative', width }}
    >
      <Input
        {...otherProps}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onMouseDownCapture={(e) => e.stopPropagation()}
        onClick={(e) => {
          if (selectTable) selectTable()
          e.stopPropagation()
        }}
        disabled={isReadOnly || otherProps.disabled}
      />
      {isHovering && !isDragging && !isReadOnly && (
        <TableHeaderOptions
          canAddNewColumn={canAddNewColumn}
          canRemoveColumn={canRemoveColumn}
          columnAccessor={columnAccessor}
          removeColumn={removeColumn}
          addNewColumn={addNewColumn}
        />
      )}
      {canResize && (
        <div
          onMouseDown={(e) => {
            e.stopPropagation()
            if (resizeHandler) resizeHandler(e)
          }}
          onTouchStart={(e) => {
            e.stopPropagation()
            if (resizeHandler) resizeHandler(e)
          }}
          className={cn(
            'absolute top-0 h-full w-0.5 bg-black/50 cursor-col-resize select-none touch-none opacity-0 hover:opacity-100',
            {
              'bg-sky-500': isResizing,
              'opacity-100': isResizing,
              'right-0': direction === 'left',
              'left-0': direction === 'right',
            },
          )}
        />
      )}
    </TableHead>
  )
}

type TableHeaderOptionsProps = {
  canRemoveColumn: boolean
  canAddNewColumn: boolean
  columnAccessor: string
  removeColumn?: (columnAccessor) => void
  addNewColumn?: (columnAccessor) => void
}
const TableHeaderOptions = ({
  canRemoveColumn,
  canAddNewColumn,
  columnAccessor,
  removeColumn,
  addNewColumn,
}: TableHeaderOptionsProps) => (
  <div className="absolute flex flex-1 right-1 top-1">
    {canRemoveColumn && removeColumn && (
      <XCircleIcon
        className="h-4 w-4 text-red-500 cursor-pointer"
        onClick={() => removeColumn(columnAccessor)}
        onMouseDownCapture={(e) => e.stopPropagation()}
      />
    )}
    {canAddNewColumn && addNewColumn && (
      <PlusCircleIcon
        className="h-4 w-4 text-blue-400 cursor-pointer"
        onClick={() => addNewColumn(columnAccessor)}
        onMouseDownCapture={(e) => e.stopPropagation()}
      />
    )}
  </div>
)

type EditableTableCellProps = CellContext<TableData, unknown> & {
  onChange: (rowIndex: number, columnId: string | number, value: string) => void
  selectTable?: () => void
  isCustomRow?: boolean
  isReadOnly?: boolean
  documentPageId: string
}
const EditableTableCell = ({
  getValue,
  onChange,
  selectTable,
  row,
  column,
  table,
  isCustomRow = false,
  isReadOnly,
  documentPageId,
}: EditableTableCellProps) => {
  const [hasChanged, setHasChanged] = useState(false)

  // prettier-ignore
  const handleOnBlur = () => {
    if (hasChanged) {
      (table.options.meta as {
        saveTableData: ({
          isCustomRow,
          rowIndex,
        }: {
          isCustomRow: boolean,
          rowIndex: number,
        }) => void
      }).saveTableData({
        isCustomRow,
        rowIndex: row.index
      })
      setHasChanged(false);
    }
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHasChanged(true)
    if (onChange) {
      const value = event.target.value
      onChange(row.index, column.id, value)
    }
  }

  return (
    <TableCell
      id={createTableCellId(column, row, documentPageId)}
      className="border-x border-solid border-gray-200 border-collapse py-0 bg-white/70"
      style={{
        width: column.getSize(),
        position: 'relative',
      }}
    >
      {isReadOnly ? (
        <span className="text-xs">{getValue<string>()}</span>
      ) : (
        <Input
          className="border-none shadow-none m-0 p-1 text-xs h-auto"
          value={getValue<string>() || ''}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onMouseDownCapture={(e) => {
            if (selectTable) {
              selectTable()
            }
            e.stopPropagation()
          }}
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      )}
    </TableCell>
  )
}

const createTableCellId = (
  column: Column<TableData, unknown>,
  row: Row<TableData>,
  documentPageId: string,
) => {
  if (column.id === 'partName') {
    return `part-${documentPageId}-${row.original.partName}`
  }
}
