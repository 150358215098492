import { useState } from 'react'
import { cn } from '@/utils'
import { Dialog, DialogTrigger } from '@/components/ui/dialog'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { DownloadPDFDialogContent } from './DownloadPDFDialogContent'

import { usePDFDownloadEnabled } from './hooks/usePDFDownloadEnabled'
import { usePDFDownloadTrigger } from './hooks/usePDFDownloadTrigger'

export const PDFDownloadButton = ({
  onDownload,
}: {
  onDownload: () => void
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const isPDFDownloadEnabled = usePDFDownloadEnabled()
  const triggerDownload = usePDFDownloadTrigger()

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={(isOpen) => {
        if (isPDFDownloadEnabled) {
          setIsDialogOpen(isOpen)
        }
      }}
    >
      <DialogTrigger
        asChild
        disabled={!isPDFDownloadEnabled}
        onClick={() => {
          if (isPDFDownloadEnabled) {
            triggerDownload()
          }
        }}
      >
        <div
          className={cn('flex items space-x-2', {
            'text-gray-400 cursor-not-allowed': !isPDFDownloadEnabled,
          })}
        >
          <ArrowDownTrayIcon className="w-4 h-4" />
          <span>Download</span>
        </div>
      </DialogTrigger>
      <DownloadPDFDialogContent
        onDownload={() => {
          setIsDialogOpen(false)
          onDownload()
        }}
      />
    </Dialog>
  )
}
