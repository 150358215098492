import { useMemo } from 'react'
import dayjs from 'dayjs'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { DocumentTemplate } from './DocumentTemplate'
import { toChunks } from '@/utils'

export const MAX_VERSIONS_PER_PAGE = 12
export const VersionHistoryDocument = ({
  versionName,
  lastUpdated,
  isLatestDocumentVersion,
}: {
  versionName: string
  lastUpdated: string
  isLatestDocumentVersion?: boolean
}) => {
  const { isLoading, data } = useDocumentPageQuery()
  const documentVersions: Array<any> = useMemo(() => {
    const documentVersion = data?.documentVersions || []
    const cleanVersions = documentVersion
      .map((version, i) => {
        let versionName
        let versionComment

        if (i === 0) {
          versionName = 'Current'
        } else {
          const prevVersion = documentVersion[i - 1]
          versionComment = prevVersion.comment
          versionName = prevVersion.name || `V${prevVersion.version_number}`
        }

        return {
          ...version,
          comment: versionComment,
          name: versionName,
        }
      })
      .splice(1, documentVersion.length - 1)
    return toChunks({
      array: cleanVersions,
      chunkSize: MAX_VERSIONS_PER_PAGE,
    })
  }, [data?.documentVersions])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!data) {
    return <div>Document not found</div>
  }

  return documentVersions.map((chunk, i) => (
    <DocumentTemplate
      id="history"
      title="Version History"
      lastUpdated={lastUpdated}
      versionName={versionName}
      isLatestDocumentVersion={isLatestDocumentVersion}
      key={i}
      order={2}
    >
      <div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Version</TableHead>
              <TableHead>Last Updated</TableHead>
              <TableHead>Notes</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {chunk.map((version, i) => (
              <TableRow key={i}>
                <TableCell className="py-1 px-2.5">{version.name}</TableCell>
                <TableCell className="py-1 px-2.5">
                  {dayjs(
                    version.modified ||
                      version.created ||
                      new Date().toISOString(),
                  ).format('MMM DD, YYYY hh:mm a')}
                </TableCell>
                <TableCell className="py-1 px-2.5">{version.comment}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </DocumentTemplate>
  ))
}
